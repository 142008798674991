export class Penalties {
  constructor({ animate, preview, update, animateOff }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.name = "Penalties";
    this.scene = "Penalties";
    this.data = [];
  }

  preview({ options }) {
    let homeScore =
      options.game.home_penalties &&
      options.game.home_penalties.reduce((prev, curr) => {
        if (curr.type === 1) {
          return prev + 1;
        }
        return prev;
      }, 0);
    let awayScore =
      options.game.away_penalties &&
      options.game.away_penalties.reduce((prev, curr) => {
        if (curr.type === 1) {
          return prev + 1;
        }
        return prev;
      }, 0);

    this.data.push({
      name: "Competition",
      value: { text: options.game?.competition?.name || "" },
    });
    this.data.push({
      name: "Competition Colour",
      value: { colour: options.game?.competition?.colour || "" },
    });
    this.data.push({
      name: "Competition Badge",
      value: { image: "${user}" + options.game?.competition?.badge || "" },
    });

    this.data.push({
      name: "Home Score",
      value: { text: homeScore },
    });
    this.data.push({
      name: "Away Score",
      value: { text: awayScore },
    });

    new Array(15).fill(1).forEach((_, index) => {
      this.data.push({
        name: "Home Penalty " + (index + 1),
        value: {
          text: options.game.home_penalties?.find((p) => p.index === index)
            ? options.game.home_penalties?.find((p) => p.index === index)?.type
            : -1,
        },
      });
    });

    new Array(15).fill(1).forEach((_, index) => {
      this.data.push({
        name: "Away Penalty " + (index + 1),
        value: {
          text: options.game.away_penalties?.find((p) => p.index === index)
            ? options.game.away_penalties?.find((p) => p.index === index)?.type
            : -1,
        },
      });
    });

    this.data.push({
      name: "Home Team Name",
      value: { text: options.game?.home_team?.name || "" },
    });

    this.data.push({
      name: "Away Team Name",
      value: { text: options.game?.away_team?.name || "" },
    });

    this.data.push({
      name: "Home Team Code",
      value: { text: options.game?.home_team?.code || "" },
    });

    this.data.push({
      name: "Away Team Code",
      value: { text: options.game?.away_team?.code || "" },
    });

    this.data.push({
      name: "Home Team Badge",
      value: {
        image: "${user}" + options.game?.home_team?.variables?.badge,
      },
    });

    this.data.push({
      name: "Home Team Secondary Badge",
      value: {
        image: "${user}" + options.game?.home_team?.variables?.alt_badge,
      },
    });

    this.data.push({
      name: "Away Team Badge",
      value: {
        image: "${user}" + options.game?.away_team?.variables?.badge,
      },
    });

    this.data.push({
      name: "Away Team Secondary Badge",
      value: {
        image: "${user}" + options.game?.away_team?.variables?.alt_badge,
      },
    });

    this.data.push({
      name: "Home Team Kit BG Colour",
      value: {
        colour:
          options.game.home_team?.variables?.[
            options.game?.home_team_kit + "_kit_bg"
          ] || "",
      },
    });
    this.data.push({
      name: "Home Team Kit Text Colour",
      value: {
        colour:
          options.game.home_team?.variables?.[
            options.game?.home_team_kit + "_kit_text"
          ] || "",
      },
    });
    this.data.push({
      name: "Away Team Kit BG Colour",
      value: {
        colour:
          options.game.away_team?.variables?.[
            options.game?.away_team_kit + "_kit_bg"
          ] || "",
      },
    });
    this.data.push({
      name: "Away Team Kit Text Colour",
      value: {
        colour:
          options.game.away_team?.variables?.[
            options.game?.away_team_kit + "_kit_text"
          ] || "",
      },
    });

    if (this.onAir) {
      this.sendUpdate(this.scene, this.data);
    } else {
      this.sendPreview(this.scene, 500, this.data, "IN");
    }
  }
  update({ options }) {
    let homeScore =
      (options.game.home_penalties &&
        options.game.home_penalties.reduce((prev, curr) => {
          if (curr.type === 1) {
            return prev + 1;
          }
          return prev;
        }, 0)) ||
      0;
    let awayScore =
      (options.game.away_penalties &&
        options.game.away_penalties.reduce((prev, curr) => {
          if (curr.type === 1) {
            return prev + 1;
          }
          return prev;
        }, 0)) ||
      0;

    this.data.push({
      name: "Competition",
      value: { text: options.game?.competition?.name || "" },
    });
    this.data.push({
      name: "Competition Colour",
      value: { colour: options.game?.competition?.colour || "" },
    });
    this.data.push({
      name: "Competition Badge",
      value: { image: "${user}" + options.game?.competition?.badge || "" },
    });

    this.data.push({
      name: "Home Score",
      value: { text: homeScore },
    });
    this.data.push({
      name: "Away Score",
      value: { text: awayScore },
    });

    new Array(20).fill(1).forEach((_, index) => {
      this.data.push({
        name: "Home Penalty " + (index + 1),
        value: {
          text: options.game.home_penalties?.find((p) => p.index === index)
            ? options.game.home_penalties?.find((p) => p.index === index)?.type
            : -1,
        },
      });
    });

    new Array(20).fill(1).forEach((_, index) => {
      this.data.push({
        name: "Away Penalty " + (index + 1),
        value: {
          text: options.game.away_penalties?.find((p) => p.index === index)
            ? options.game.away_penalties?.find((p) => p.index === index)?.type
            : -1,
        },
      });
    });

    this.data.push({
      name: "Home Team Name",
      value: { text: options.game?.home_team?.name || "" },
    });

    this.data.push({
      name: "Away Team Name",
      value: { text: options.game?.away_team?.name || "" },
    });

    this.data.push({
      name: "Home Team Code",
      value: { text: options.game?.home_team?.code || "" },
    });

    this.data.push({
      name: "Away Team Code",
      value: { text: options.game?.away_team?.code || "" },
    });

    this.data.push({
      name: "Home Team Badge",
      value: {
        image: "${user}" + options.game?.home_team?.variables?.badge,
      },
    });

    this.data.push({
      name: "Home Team Secondary Badge",
      value: {
        image: "${user}" + options.game?.home_team?.variables?.alt_badge,
      },
    });

    this.data.push({
      name: "Away Team Badge",
      value: {
        image: "${user}" + options.game?.away_team?.variables?.badge,
      },
    });

    this.data.push({
      name: "Away Team Secondary Badge",
      value: {
        image: "${user}" + options.game?.away_team?.variables?.alt_badge,
      },
    });

    this.data.push({
      name: "Home Team Kit BG Colour",
      value: {
        colour:
          options.game.home_team?.variables?.[
            options.game?.home_team_kit + "_kit_bg"
          ] || "",
      },
    });
    this.data.push({
      name: "Home Team Kit Text Colour",
      value: {
        colour:
          options.game.home_team?.variables?.[
            options.game?.home_team_kit + "_kit_text"
          ] || "",
      },
    });
    this.data.push({
      name: "Away Team Kit BG Colour",
      value: {
        colour:
          options.game.away_team?.variables?.[
            options.game?.away_team_kit + "_kit_bg"
          ] || "",
      },
    });
    this.data.push({
      name: "Away Team Kit Text Colour",
      value: {
        colour:
          options.game.away_team?.variables?.[
            options.game?.away_team_kit + "_kit_text"
          ] || "",
      },
    });
    this.sendUpdate(this.scene, this.data);
  }
  animate({ options }) {
    this.onAir = true;
    this.sendAnimate(this.scene, this.data);
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff(this.scene, []);
  }
}
