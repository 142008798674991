import React, { useRef, useContext, useEffect } from "react";
import styled from "styled-components";
import gql from "graphql-tag";
import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  useMutation,
  useQuery,
} from "@apollo/client";

import { setContext } from "@apollo/client/link/context";

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("token");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `${token}` : "",
    },
  };
});

const httpLink = createHttpLink({
  uri:
    (window.ENV?.REACT_APP_PROJECT_SERVER ||
      process.env.REACT_APP_PROJECT_SERVER) + "/graphql",
});

const user_client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(httpLink),
});

const LOGIN = gql`
  mutation Login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      token
      _id
    }
  }
`;

const LOGIN_WITH_TOKEN = gql`
  mutation LoginWithToken($token: String!) {
    loginWithToken(token: $token) {
      token
      _id
    }
  }
`;

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(14, 18, 25);
  opacity: 1;
  transition: 0.5s opacity;
  z-index: 100;
`;

const H1 = styled.h1`
  font-size: 2em;
  font-weight: 100;
`;
const Main = styled.div`
  width: 300px;
  margin-top: -24%;
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
`;
const Input = styled.input`
  background-color: #1f222b;
  border-radius: 4px;
  border: none;
  padding: 1em;
  margin-bottom: 1em;
  outline: none;
  color: #fff;
`;
const Button = styled.div`
  background-color: #c11938;
  padding: 1em;
  border-radius: 4px;
  min-width: 80px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  margin-top: 2em;
`;
const Error = styled.div`
  color: #c11938;
  padding: 1em;
  display: flex;
  justify-content: center;
`;
export default function Login({ loggedIn }) {
  const [login, { data }] = useMutation(LOGIN, {
    client: user_client,
    onCompleted: (data) => {
      localStorage.setItem("user-id", data.login._id);
      localStorage.setItem("token", data.login.token);
      loggedIn();
    },
  });
  const [loginWithToken, { data: token_data }] = useMutation(LOGIN_WITH_TOKEN, {
    client: user_client,
    onCompleted: (data) => {
      localStorage.setItem("user-id", data.loginWithToken._id);
      localStorage.setItem("token", data.loginWithToken.token);
      loggedIn();
    },
  });
  const usernameRef = useRef();
  const passwordRef = useRef();
  const buttonRef = useRef();

  useEffect(() => {
    let callback = (event) => {
      try {
        let data = JSON.parse(event?.data);
        if (data?.event === "login") {
          loginWithToken({ variables: { token: data.data.token } });
        }
      } catch (err) {}
    };
    window.addEventListener("message", callback);
    return () => {
      window.removeEventListener("message", callback);
    };
  }, []);

  return (
    <Container>
      <Main>
        <H1>Welcome</H1>

        <Form>
          <Input ref={usernameRef} placeholder={"Username"} tabIndex="1" />
          <Input
            tabIndex="2"
            ref={passwordRef}
            placeholder={"Password"}
            type={"password"}
            autoComplete="current-password"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                login({
                  variables: {
                    username: usernameRef.current.value,
                    password: passwordRef.current.value,
                  },
                });
              }
            }}
          />
          <Button
            tabIndex="3"
            ref={buttonRef}
            onClick={() => {
              login({
                variables: {
                  username: usernameRef.current.value,
                  password: passwordRef.current.value,
                },
              });
            }}
          >
            Login
          </Button>
        </Form>
      </Main>
    </Container>
  );
}
