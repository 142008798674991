import React, { useState, useEffect } from "react";
import styled from "styled-components";
import LeftSideBar from "../LeftSideBar/LeftSideBar";
const Main = styled.div`
  display: flex;
`;
const LeftBar = styled.div`
  display: flex;
  width: 44px;
  box-shadow: 3px -1px 3px 0 rgb(0 0 0 / 42%);
  background-color: #202731;
`;
const Content = styled.div`
  display: flex;
  padding: 1em;
  box-sizing: border-box;
  flex: 1;
  flex-direction: column;
  height: 100vh;
`;
const ActionPanel = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 1em;
  box-sizing: border-box;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  background-color: #181d25;
  padding: 1em;
  align-items: center;
`;
const TextInput = styled.input`
  background-color: ${(props) => props.theme.primaryBackgroundColour};
  border-radius: 4px;
  border: none;
  padding: 1em;
  outline: none;
  color: ${(props) => props.theme.primaryFontColour};
  margin-right: 1em;
  min-width: 250px;
  margin-left: 1em;
`;
const Label = styled.div`
  min-width: 150px;
`;
export default function Config({ setView }) {
  const [MSEAddress, setMSEAddress] = useState(window.MSEAddress);
  const [showName, setShowName] = useState(window.showName);
  const [vizProfile, setVizProfile] = useState(window.vizProfile);
  const [dataUrl, setDataUrl] = useState(window.dataUrl);
  const [hyperLink, setHyperLink] = useState(window.hyperLink);
  useEffect(() => {
    window.MSEAddress = MSEAddress;
    window.localStorage.setItem("MSEAddress", MSEAddress);
  }, [MSEAddress]);

  useEffect(() => {
    window.showName = showName;
    window.localStorage.setItem("showName", showName);
  }, [showName]);

  useEffect(() => {
    window.vizProfile = vizProfile;
    window.localStorage.setItem("vizProfile", vizProfile);
  }, [vizProfile]);
  useEffect(() => {
    window.dataUrl = dataUrl;
    window.localStorage.setItem("dataUrl", dataUrl);
  }, [dataUrl]);

  useEffect(() => {
    window.hyperLink = hyperLink;
    window.localStorage.setItem("hyperLink", hyperLink);
  }, [hyperLink]);
  return (
    <Main>
      <LeftBar>
        <LeftSideBar setView={setView} />
      </LeftBar>
      <Content>
        Settings
        <ActionPanel>
          <Label>MSE IP:</Label>

          <TextInput
            value={MSEAddress}
            onChange={(e) => {
              setMSEAddress(e.currentTarget.value);
            }}
          ></TextInput>
        </ActionPanel>
        <ActionPanel>
          <Label>Trio Show name:</Label>

          <TextInput
            value={showName}
            onChange={(e) => {
              setShowName(e.currentTarget.value);
            }}
          ></TextInput>
        </ActionPanel>
        <ActionPanel>
          <Label>Viz Profile:</Label>

          <TextInput
            value={vizProfile}
            onChange={(e) => {
              setVizProfile(e.currentTarget.value);
            }}
          ></TextInput>
        </ActionPanel>
        <ActionPanel>
          <Label>HYPER Link IP:</Label>

          <TextInput
            value={hyperLink}
            onChange={(e) => {
              setHyperLink(e.currentTarget.value);
            }}
          ></TextInput>
        </ActionPanel>
        <ActionPanel>
          <Label>Data URL:</Label>

          <TextInput
            value={dataUrl}
            onChange={(e) => {
              setDataUrl(e.currentTarget.value);
            }}
          ></TextInput>
        </ActionPanel>
      </Content>
    </Main>
  );
}
