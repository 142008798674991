import React, { useContext } from "react";
import styled from "styled-components";
import { FaInfo } from "react-icons/fa";
import { APIContext } from "../../../contexts/APIContext";
import { GraphicsContext } from "../../../contexts/GraphicsContext";
import { UIContext } from "../../../contexts/UIContext";
const Main = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
const Title = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  font-size: 0.7em;
`;
const InfoIcon = styled(FaInfo)`
  margin-right: 2px;
  position: absolute;
  left: 3px;
  font-size: 1.5em;
`;
const Content = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  font-size: 0.6em;
  align-items: center;
`;

export default function Opta79({ item, game }) {
  return (
    <Main>
      <Title>
        <InfoIcon />
        Interruption in data{" "}
        {item?.outcome === 0 ? " started" : item?.outcome === 1 ? " ended" : ""}
      </Title>
      <Content>
        {item?.qualifier?.findIndex((q) => q?.qualifierId === 344) > -1
          ? "Video Coverage Lost"
          : ""}
      </Content>
    </Main>
  );
}
