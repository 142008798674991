import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { APIContext } from "../../contexts/APIContext";
const Main = styled.div`
  display: flex;
  justify-content: center;
  margin: 0.5em;
  width: 100%;
`;
const KitContainer = styled.div`
  border: ${(props) => (props.selected ? "2px solid rgb(10, 219, 147)" : "")};
  margin-right: 0.5em;
  border-radius: 4px;
  min-width: 100px;
  min-height: 40px;
  font-size: 30px;
  display: flex;
  justify-content: center;
`;
const KitDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100px;
  min-height: 40px;
  padding: 0.5em;
  margin: 2px;
  opacity: ${(props) => (props.selected ? 1 : 0.4)};
  cursor: pointer;
  display: flex;
`;
const Half = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;
function KitSelection({ game }) {
  const { updateKit } = useContext(APIContext);
  const [homeKit, setHomeKit] = useState("home");
  const [awayKit, setAwayKit] = useState("home");
  useEffect(() => {
    setHomeKit(game?.home_team_kit);
    setAwayKit(game?.away_team_kit);
  }, [game]);
  return (
    <Main>
      <Half>
        <Kit
          team_kit={homeKit}
          team={game?.home_team}
          type="home"
          onClick={() => {
            updateKit({
              id: game?._id,
              team: "home",
              kit: "home",
            }).then(() => {
              setHomeKit("home");
            });
          }}
        />
        <Kit
          team_kit={homeKit}
          team={game?.home_team}
          type="away"
          onClick={() => {
            updateKit({
              id: game?._id,
              team: "home",
              kit: "away",
            }).then(() => {
              setHomeKit("away");
            });
          }}
        />
        <Kit
          team_kit={homeKit}
          team={game?.home_team}
          type="third"
          onClick={() => {
            updateKit({
              id: game?._id,
              team: "home",
              kit: "third",
            }).then(() => {
              setHomeKit("third");
            });
          }}
        />
      </Half>
      <Half>
        <Kit
          team_kit={awayKit}
          team={game?.away_team}
          type="home"
          onClick={() => {
            updateKit({
              id: game?._id,
              team: "away",
              kit: "home",
            }).then(() => {
              setAwayKit("home");
            });
          }}
        />
        <Kit
          team_kit={awayKit}
          team={game?.away_team}
          type="away"
          onClick={() => {
            updateKit({
              id: game?._id,
              team: "away",
              kit: "away",
            }).then(() => {
              setAwayKit("away");
            });
          }}
        />
        <Kit
          team_kit={awayKit}
          team={game?.away_team}
          type="third"
          onClick={() => {
            updateKit({
              id: game?._id,
              team: "away",
              kit: "third",
            }).then(() => {
              setAwayKit("third");
            });
          }}
        />
      </Half>
    </Main>
  );
}

function Kit({ team, type, team_kit, onClick }) {
  return (
    <KitContainer selected={team_kit === type} onClick={onClick}>
      <KitDiv
        selected={team_kit === type}
        style={{
          backgroundColor: team?.variables?.[type + "_kit_bg"],
          color: team?.variables?.[type + "_kit_text"],
        }}
        onClick={() => {}}
      >
        {team?.code}
      </KitDiv>
    </KitContainer>
  );
}
export default KitSelection;
