import React, { useContext } from "react";
import HyperLogo from "../../Images/HyperLogo.png";
import styled from "styled-components";
import { UserContext } from "../../contexts/UserContext";
import { Button } from "../../Theme/Hyper";
const Main = styled.div`
  height: 35px;
  background-color: #202731;
  z-index: 1000;
  position: -webkit-sticky;
  position: sticky;
  box-shadow: 0px 4px 12px -20px rgb(0 0 0 / 20%),
    0px 8px 20px 2px rgb(0 0 0 / 14%), 0 9px 46px 8px rgb(0 0 0 / 12%);
  display: flex;
  align-items: center;
  border-bottom: 1px solid #030304;
`;

const Left = styled.div`
  width: 40%;
  padding-left: 1em;
  display: flex;
`;

const Right = styled.div`
  width: 40%;
  padding-right: 1em;
  display: flex;
  align-items: center;
`;

const Profile = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
`;

const Logo = styled.img`
  margin-left: auto;
  margin-right: auto;
  height: 50%;
`;
export default function NavBar() {
  const { user, signOut } = useContext(UserContext);
  return (
    <Main>
      <Left></Left>
      <Logo src={HyperLogo} alt="Hyper Logo" />
      <Right>
        {user && (
          <Profile>
            {user?.username}
            <Button
              style={{ border: "1px solid #fff", marginLeft: "10px" }}
              onClick={() => {
                signOut();
              }}
            >
              Sign Out
            </Button>
          </Profile>
        )}
      </Right>
    </Main>
  );
}
