import moment from "moment";

export class Group {
  constructor({ animate, preview, update, animateOff }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.name = "Group Table";
    this.scene = "Group Table";
    this.data = [];
  }

  preview({ options }) {
    this.onAir = false;

    this.data = [];

    this.data.push({
      name: "Competition",
      value: { text: options.game?.competition?.name || "" },
    });
    this.data.push({
      name: "Competition Colour",
      value: { colour: options.game?.competition?.colour || "" },
    });
    this.data.push({
      name: "Competition Badge",
      value: { image: "${user}" + options.game?.competition?.badge || "" },
    });

    this.data.push({
      name: "Title",
      value: { text: options?.group?.title },
    });

    options?.group?.teams?.map((team, index) => {
      let row = index + 1;

      this.data.push({
        name: "Team Name " + row,
        value: { text: team?.team?.name || "" },
      });

      this.data.push({
        name: "Team Badge " + row,
        value: {
          image: "${user}" + team?.team?.variables?.badge,
        },
      });

      this.data.push({
        name: "Team Secondary Badge " + row,
        value: {
          image: "${user}" + team?.team?.variables?.alt_badge,
        },
      });

      this.data.push({
        name: "Team Video Badge " + row,
        value: {
          video: "${user}" + team?.team?.variables?.video_badge,
        },
      });

      this.data.push({
        name: "Team Points " + row,
        value: { text: team?.points || "0" },
      });

      this.data.push({
        name: "Team Played " + row,
        value: { text: team?.played || "0" },
      });

      this.data.push({
        name: "Team Won " + row,
        value: { text: team?.won || "0" },
      });

      this.data.push({
        name: "Team Lost " + row,
        value: { text: team?.lost || "0" },
      });
    });

    this.sendPreview(this.scene, 500, this.data, "IN");
  }
  animate({ options }) {
    this.onAir = true;
    this.sendAnimate(this.scene, this.data);
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff(this.scene, []);
  }
}
