import React, { useContext } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import { APIContext } from "../../contexts/APIContext";
import { DataContext } from "../../contexts/DataContext";
import { GraphicsContext } from "../../contexts/GraphicsContext";
import { UIContext, VIEW_CONSTS } from "../../contexts/UIContext";
import useLocalStorage from "../../Hooks/useLocalStorage";
import { reset } from "../RightSideBar/ClubSponsors";
import Input from "../UI/Form/Input";
const Main = styled.div`
  display: flex;
  flex-direction: column;
`;

const ActionPanel = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 1em;
  box-sizing: border-box;
  height: 100px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  background-color: #181d25;
`;

const Button = styled.div`
  display: flex;
  background-color: #202731;

  width: 90%;
  box-sizing: border-box;
  border-radius: 4px;
  margin: 1em;
  justify-content: center;
  align-items: center;
  max-width: 200px;
  cursor: pointer;
  :hover {
    background-color: #db0a41;
  }
`;
const Row = styled.div`
  display: flex;
  align-items: center;
  width: 45%;
`;

const Red = styled.div`
  display: flex;
  width: 40px;
  height: 20px;
  background-color: ${({ selected }) => (selected ? "#d93232" : "")};
  border: 1px solid #d93232;
  margin: 5px;
  border-radius: 2px;
  cursor: pointer;
  :hover {
    background-color: #d93232;
  }
`;
const ScoreButton = styled(Button)`
  width: 100px;
  align-items: center;
  justify-content: center;
  font-size: 2em;
  height: 100%;
  box-sizing: border-box;
  margin: 0px;
  opacity: ${(props) => (props.selected || !props.red ? "1" : "0.2")};
  background-color: ${(props) => (props.red ? "#e51b4c" : "")};
`;

const RedButton = styled(ScoreButton)`
  margin-left: 10px;
`;
const Middle = styled.div`
  display: flex;
  width: 10%;
  justify-content: space-evenly;
  align-items: center;
  margin-top: -10px;
`;
export default function ClockEdit({ game }) {
  const { updateGame } = useContext(APIContext);
  const { updateGame: updateGameData, update } = useContext(DataContext);
  const { updateGraphic, previewGraphic } = useContext(GraphicsContext);
  const history = useHistory();
  const { periods, setPeriods, homePenalties, awayPenalties } =
    useContext(UIContext);
  const [homeReds, setHomeReds] = useLocalStorage(game?._id + "home_reds");
  const [awayReds, setAwayReds] = useLocalStorage(game?._id + "away_reds");
  const [caption, setCaption] = useLocalStorage(
    "hyper_football_custom_slate_caption"
  );
  let period = {};
  if (periods.length > 0) {
    period = periods?.sort((a, b) => b.number - a.number)?.[0];
  }

  return (
    <Main>
      <ActionPanel>
        {new Array(
          game?.config?.number_of_periods ? game?.config?.number_of_periods : 2
        )
          ?.fill(1)
          ?.map((_, index) => {
            let period_num = index + 1;
            return (
              <Button
                onClick={() => {
                  reset();
                  let newData = { ...JSON.parse(JSON.stringify(game)) };
                  newData.live_data.periods = newData.live_data.periods?.filter(
                    (p) => p.number < period_num
                  );
                  newData.live_data.periods.push({
                    kick_off: null,
                    number: period_num,
                  });
                  setPeriods(newData.live_data.periods);
                  updateGraphic("CLOCK", {
                    game: game,
                    period: { kick_off: null, number: period_num },
                  });
                  updateGame({ id: game._id, live_data: newData.live_data });
                }}
              >
                Reset Clock{" "}
                {(game?.config?.half_duration * index)
                  ?.toString()
                  ?.padStart(2, "0")}
                :00
              </Button>
            );
          })}
        {new Array(game?.config?.number_of_et ? game?.config?.number_of_et : 2)
          ?.fill(1)
          ?.map((_, index) => {
            let period_num =
              index +
              1 +
              (game?.config?.number_of_periods
                ? game?.config?.number_of_periods
                : 2);
            return (
              <Button
                onClick={() => {
                  let newData = { ...JSON.parse(JSON.stringify(game)) };
                  newData.live_data.periods = newData.live_data.periods?.filter(
                    (p) => p.number < period_num
                  );
                  newData.live_data.periods.push({
                    kick_off: null,
                    number: period_num,
                  });
                  setPeriods(newData.live_data.periods);
                  updateGraphic("CLOCK", {
                    game: game,
                    period: { kick_off: null, number: period_num },
                  });
                  updateGame({ id: game._id, live_data: newData.live_data });
                }}
              >
                Reset Clock ET{index + 1}
              </Button>
            );
          })}
        {/* <Button
          onClick={() => {
            let newData = { ...JSON.parse(JSON.stringify(game)) };
            newData.live_data.periods = [
              { kick_off: null, number: 1 },
              { number: 2, kick_off: null },
            ];
            updateGraphic("CLOCK", {
              game: game,
              period: { number: 2, kick_off: null },
            });
            updateGame({ id: game._id, live_data: newData.live_data });
          }}
        >
          Reset Clock {game?.config?.half_duration || "45"}:00
        </Button> */}
        {/* <Button
          onClick={() => {
            let newData = { ...JSON.parse(JSON.stringify(game)) };
            newData.live_data.periods = [
              { kick_off: null, number: 1 },
              { number: 2, kick_off: null },
              { number: 3, kick_off: null },
            ];
            updateGraphic("CLOCK", {
              game: game,
              period: { number: 3, kick_off: null },
            });
            updateGame({ id: game._id, live_data: newData.live_data });
          }}
        >
          Reset Clock ET1
        </Button>
        <Button
          onClick={() => {
            let newData = { ...JSON.parse(JSON.stringify(game)) };
            newData.live_data.periods = [
              { kick_off: null, number: 1 },
              { number: 2, kick_off: null },
              { number: 3, kick_off: null },
              { number: 4, kick_off: null },
            ];
            updateGraphic("CLOCK", {
              game: game,
              period: { number: 4, kick_off: null },
            });
            updateGame({ id: game._id, live_data: newData.live_data });
          }}
        >
          Reset Clock ET2
        </Button> */}
        {/* <Button
          onClick={() => {
            update({
              type: "update_game",
              _id: game._id,
              variable: "live_data.periods",
              data: [
                { number: 1, kick_off: null },
                { number: 2, kick_off: null },
              ],
            });
          }}
        >
          Reset Clock 80:00
        </Button> */}
        {period?.kick_off && (
          <>
            <Button
              onClick={() => {
                let newData = { ...JSON.parse(JSON.stringify(game)) };
                let new_periods = [...periods];
                let index = new_periods.findIndex(
                  (p) => p.number === period.number
                );

                new_periods[index].kick_off += 60000;
                newData.live_data.periods[index].kick_off =
                  new_periods[index].kick_off;
                setPeriods(new_periods);
                updateGraphic("CLOCK", {
                  game: game,
                  period: new_periods[index],
                });
                updateGame({ id: game._id, live_data: newData.live_data });
              }}
            >
              -1 Min
            </Button>

            <Button
              onClick={() => {
                let newData = { ...JSON.parse(JSON.stringify(game)) };
                let new_periods = [...periods];
                let index = new_periods.findIndex(
                  (p) => p.number === period.number
                );

                new_periods[index].kick_off -= 60000;
                newData.live_data.periods[index].kick_off =
                  new_periods[index].kick_off;
                setPeriods(new_periods);
                updateGraphic("CLOCK", {
                  game: game,
                  period: new_periods[index],
                });
                updateGame({ id: game._id, live_data: newData.live_data });
              }}
            >
              +1 Min
            </Button>

            <Button
              onClick={() => {
                let newData = { ...JSON.parse(JSON.stringify(game)) };
                let new_periods = [...periods];
                let index = new_periods.findIndex(
                  (p) => p.number === period.number
                );

                new_periods[index].kick_off += 1000;
                newData.live_data.periods[index].kick_off =
                  new_periods[index].kick_off;
                setPeriods(new_periods);
                updateGraphic("CLOCK", {
                  game: game,
                  period: new_periods[index],
                });
                updateGame({ id: game._id, live_data: newData.live_data });
              }}
            >
              -1 Sec
            </Button>
            <Button
              onClick={() => {
                let newData = { ...JSON.parse(JSON.stringify(game)) };
                let new_periods = [...periods];
                let index = new_periods.findIndex(
                  (p) => p.number === period.number
                );
                new_periods[index].kick_off -= 1000;
                newData.live_data.periods[index].kick_off =
                  new_periods[index].kick_off;
                setPeriods(new_periods);
                updateGraphic("CLOCK", {
                  game: game,
                  period: new_periods[index],
                });
                updateGame({ id: game._id, live_data: newData.live_data });
              }}
            >
              +1 Sec
            </Button>
          </>
        )}
      </ActionPanel>
      <ActionPanel>
        <Button
          onClick={() => {
            setPeriods([{ kick_off: null, number: 1 }]);
            updateGame({
              id: game._id,
              live_data: { periods: [{ kick_off: null, number: 1 }] },
              lineup: { home: [], away: [] },
              home_scorers: [],
              away_scorers: [],
            });
          }}
        >
          RESET GAME
        </Button>
        <Button
          onClick={() => {
            updateGame({
              id: game._id,
              home_scorers: [],
              away_scorers: [],
            });
          }}
        >
          RESET GOALS
        </Button>
      </ActionPanel>
      <ActionPanel>
        <Button
          onClick={() => {
            history.push("/" + game._id + "/penalties");
          }}
        >
          Penalty Mode
        </Button>
      </ActionPanel>
      <ActionPanel>
        <Row>
          <RedButton
            red
            selected={homeReds?.find((r) => r.game === game._id)?.one}
            onContextMenu={(e) => {
              e.preventDefault();
            }}
            onMouseDown={(e) => {
              let new_reds = [...(homeReds || [])];
              if (new_reds?.findIndex((r) => r.game === game._id) === -1) {
                new_reds.push({ game: game._id });
              }
              let old_reds = new_reds?.find((r) => r.game === game._id);
              old_reds.one = !old_reds.one;

              setHomeReds(new_reds);
            }}
          ></RedButton>
          <RedButton
            red
            selected={homeReds?.find((r) => r.game === game._id)?.two}
            onMouseDown={(e) => {
              let new_reds = [...(homeReds || [])];
              if (new_reds?.findIndex((r) => r.game === game._id) === -1) {
                new_reds.push({ game: game._id });
              }
              let old_reds = new_reds?.find((r) => r.game === game._id);
              old_reds.two = !old_reds.two;

              setHomeReds(new_reds);
            }}
          ></RedButton>
          <RedButton
            red
            selected={homeReds?.find((r) => r.game === game._id)?.three}
            onMouseDown={(e) => {
              let new_reds = [...(homeReds || [])];
              if (new_reds?.findIndex((r) => r.game === game._id) === -1) {
                new_reds.push({ game: game._id });
              }
              let old_reds = new_reds?.find((r) => r.game === game._id);
              old_reds.three = !old_reds.three;

              setHomeReds(new_reds);
            }}
          ></RedButton>
        </Row>
        <Middle />
        <Row>
          <RedButton
            red
            selected={awayReds?.find((r) => r.game === game._id)?.one}
            onMouseDown={(e) => {
              let new_reds = [...(awayReds || [])];
              if (new_reds?.findIndex((r) => r.game === game._id) === -1) {
                new_reds.push({ game: game._id });
              }
              let old_reds = new_reds?.find((r) => r.game === game._id);
              old_reds.one = !old_reds.one;

              setAwayReds(new_reds);
            }}
          ></RedButton>
          <RedButton
            red
            selected={awayReds?.find((r) => r.game === game._id)?.two}
            onMouseDown={(e) => {
              let new_reds = [...(awayReds || [])];
              if (new_reds?.findIndex((r) => r.game === game._id) === -1) {
                new_reds.push({ game: game._id });
              }
              let old_reds = new_reds?.find((r) => r.game === game._id);
              old_reds.two = !old_reds.two;

              setAwayReds(new_reds);
            }}
          ></RedButton>
          <RedButton
            red
            selected={awayReds?.find((r) => r.game === game._id)?.three}
            onMouseDown={(e) => {
              let new_reds = [...(awayReds || [])];
              if (new_reds?.findIndex((r) => r.game === game._id) === -1) {
                new_reds.push({ game: game._id });
              }
              let old_reds = new_reds?.find((r) => r.game === game._id);
              old_reds.three = !old_reds.three;

              setAwayReds(new_reds);
            }}
          ></RedButton>
        </Row>
      </ActionPanel>
      {(window.ENV?.REACT_APP_IMG || process.env.REACT_APP_IMG) && (
        <ActionPanel>
          <Button
            onClick={() => {
              previewGraphic("MatchID", {
                game: game,
                highlights: true,
              });
            }}
          >
            Highlights Slate
          </Button>
          <Button
            onClick={() => {
              debugger;
              previewGraphic("Score", {
                game: {
                  ...game,
                  home_penalties: homePenalties,
                  away_penalties: awayPenalties,
                },
                type:
                  homePenalties?.length > 0 || awayPenalties?.length > 0
                    ? "PENS"
                    : "FT",
                highlights: true,
              });
            }}
          >
            FT Score Highlights
          </Button>
          <Button
            onClick={() => {
              debugger;
              previewGraphic("Score", {
                game: {
                  ...game,
                  home_penalties: homePenalties,
                  away_penalties: awayPenalties,
                },
                type: "ABANDONED",
                highlights: true,
              });
            }}
          >
            Match Abandoned Slate
          </Button>
          <Button
            onClick={() => {
              previewGraphic("Match Abandoned", {
                game: {
                  ...game,
                },
              });
            }}
          >
            Match Abandoned LT
          </Button>
          <Button
            onClick={() => {
              previewGraphic("Match Delayed", {
                game: {
                  ...game,
                },
                type: "DELAYED",
                highlights: true,
              });
            }}
          >
            Match Delayed LT
          </Button>
          <Button
            onClick={() => {
              previewGraphic("Match Postponed", {
                game: {
                  ...game,
                },
              });
            }}
          >
            Match Postponed LT
          </Button>
        </ActionPanel>
      )}
      {(window.ENV?.REACT_APP_IMG || process.env.REACT_APP_IMG) && (
        <ActionPanel>
          <Button
            onClick={() => {
              previewGraphic("FFTechnicalSlate", {
                game: {
                  ...game,
                },
              });
            }}
          >
            Technical Slate
          </Button>
          <Button
            onClick={() => {
              previewGraphic("FFWeatherSlate", {
                game: {
                  ...game,
                },
              });
            }}
          >
            Weather Slate
          </Button>
          <Button
            onClick={() => {
              previewGraphic("FFCancelledSlate", {
                game: {
                  ...game,
                },
              });
            }}
          >
            Cancelled Slate
          </Button>
          <Button
            onClick={() => {
              previewGraphic("FFPostponedSlate", {
                game: {
                  ...game,
                },
              });
            }}
          >
            Postponed Slate
          </Button>
        </ActionPanel>
      )}
      {(window.ENV?.REACT_APP_IMG || process.env.REACT_APP_IMG) && (
        <ActionPanel>
          <Input
            value={caption}
            title="Caption"
            onChange={(e) => {
              setCaption(e.target.value);
            }}
          />
          <Button
            onClick={() => {
              previewGraphic("FFCustomSlate", {
                game: {
                  ...game,
                },
                caption,
              });
            }}
          >
            Custom FF Slate
          </Button>
        </ActionPanel>
      )}
    </Main>
  );
}
