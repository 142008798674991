import React, { useContext } from "react";

import { IoMdClose } from "react-icons/io";

import styled from "styled-components";
import { UIContext } from "../../contexts/UIContext";
import { Button } from "../../Theme/Hyper";
const Main = styled.div`
  display: flex;
  background-color: rgb(32, 39, 49);
  height: 29px;
  border-radius: 4px 4px 0px 0px;
  border-bottom: 1px solid rgb(14, 18, 25);
  align-items: center;
  font-weight: 100;
  color: rgb(67 80 105);
  /* -webkit-app-region: drag; */
`;
const HyperIcon = styled.img`
  height: 25px;
  width: auto;
  margin-right: 0.5em;
  margin-left: 0.5em;
`;
const Right = styled.div`
  margin-left: auto;
  display: flex;
  height: 100%;
`;

const Icon = styled.div`
  display: flex;
  height: 30px;
  width: 30px;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  :hover {
    background-color: ${(props) =>
      props.close ? "#d01235" : "rgb(67 80 105)"};
    color: #fff;
  }
  > svg {
    :hover {
      background-color: ${(props) =>
        props.close ? "#d01235" : "rgb(67 80 105)"};
      color: #fff;
    }
  }
`;

const ToggleButton = styled(Button)`
  margin: 5px;
  height: 10px;
  background-color: ${(props) => (props.selected ? "#14ec8e" : "")};
  color: ${(props) => (props.selected ? "#353535" : "#fff")};
  :hover {
    background-color: "red";
  }
`;

const HyperLogo = styled.img`
  margin-left: auto;
  margin-right: auto;
  height: 50%;
`;
const Buttons = styled.div`
  display: flex;
  margin-left: 17px;
`;
const Column = styled.div`
  display: flex;
  width: 33.3%;
  height: 30px;
  align-items: center;
`;

export default function WindowTitleBar() {
  const win = window.remote.getCurrentWindow();
  const { setUIView, uiView } = useContext(UIContext);

  return (
    <Main>
      <Column>
        <Buttons>
          <ToggleButton
            selected={uiView === 0}
            onClick={() => {
              setUIView(0);
              document.getElementsByTagName("iframe")[0].contentWindow.focus();
            }}
          >
            LIVE
          </ToggleButton>
          <ToggleButton
            selected={uiView === 1}
            onClick={() => {
              setUIView(1);
              document.getElementsByTagName("body")[0].focus();
            }}
          >
            FOOTBALL
          </ToggleButton>
          <ToggleButton
            selected={uiView === 2}
            onClick={() => {
              setUIView(2);
              document.getElementsByTagName("body")[0].focus();
            }}
          >
            MANAGEMENT
          </ToggleButton>
        </Buttons>
      </Column>
      <Column style={{ justifyContent: "center" }}>
        {/* <HyperLogo src="/HyperLogo.png" /> */}
      </Column>
      <Column>
        <Right>
          {/* <Icon
          onClick={() => {
            win.minimize();
          }}
        >
          <FaRegWindowMinimize></FaRegWindowMinimize>
        </Icon>
        <Icon
          onClick={() => {
            win.maximize();
          }}
        >
          <FaRegWindowMaximize></FaRegWindowMaximize>
        </Icon> */}
          <Icon
            close
            onClick={() => {
              win.close();
            }}
          >
            <IoMdClose />
          </Icon>
        </Right>
      </Column>
    </Main>
  );
}
