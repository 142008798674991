import React from "react";
import styled from "styled-components";
import moment from "moment";
const Button = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #181d25;
  min-height: 50px;
  width: 90%;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 1em;
  justify-content: center;
  align-items: center;
  padding: 0.2em;
  cursor: pointer;
  :hover {
    background-color: #db0a41;
  }
`;
const Title = styled.div`
  display: flex;
  justify-content: cetner;
`;
const Caption = styled.div`
  display: flex;
  justify-content: center;
  font-size: 0.8em;
  text-align: center;
`;
export default function EFLPlayer({
  player,
  previewGraphic,
  game,
  sky,
  countries,
  period,
  selectedSubPlayer,
  allOptaEvents,
}) {
  return (
    <>
      <Button
        onClick={() => {
          previewGraphic("Name", {
            player: player,
            subtext: player?.team?.name,
            team: player?.team,
            sky: sky,
            countries: countries,
            game,
          });
        }}
      >
        Name
      </Button>

      <Button
        onClick={() => {
          previewGraphic("Card", {
            player,
            cardType: 1,
            team: player?.team,

            countries: countries,
            game,
            clock: true,
          });
        }}
      >
        Yellow Card
      </Button>

      <Button
        onClick={() => {
          previewGraphic("Card", {
            player,
            cardType: 2,
            team: player?.team,
            game,
            countries: countries,
            clock: true,
          });
        }}
      >
        Double Yellow Card
      </Button>

      <Button
        onClick={() => {
          previewGraphic("Card", {
            player,
            cardType: 3,
            team: player?.team,
            game,
            countries: countries,
            clock: true,
          });
        }}
      >
        Red Card
      </Button>

      {!player.title && (
        <Button
          onClick={() => {
            previewGraphic("TouchMap", {
              player,
              team: player?.team,
              opta_events: allOptaEvents,
              game,
            });
          }}
        >
          Touch Map
        </Button>
      )}

      {!player.title && (
        <Button
          onClick={() => {
            previewGraphic("POTM", {
              player,
              team: player?.team,
              game,
            });
          }}
        >
          POTM
        </Button>
      )}

      {player.stories &&
        player.stories.map((stat) => {
          return (
            <Button
              onClick={() => {
                previewGraphic("Name", {
                  team: player?.team,
                  player: player,
                  sky: sky,
                  subtext: stat.story,
                  countries: countries,
                  shirt_number:
                    player.shirt_number ?? player.opta_shirt_number ?? "",
                  game,
                });
              }}
            >
              <Caption>{stat.story}</Caption>
            </Button>
          );
        })}
      {selectedSubPlayer && (
        <Button
          onClick={() => {
            previewGraphic("Substitution", {
              players: [selectedSubPlayer, player],
              team: player?.team,
              game: game,
              clock: true,
            });
          }}
        >
          Substitution
        </Button>
      )}
    </>
  );
}
