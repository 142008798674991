const defaultStats = [
  { category: "shots", home: 0, away: 0 },
  {
    category: "shotsOnTarget",
    home: 0,
    away: 0,
  },

  { category: "corners", home: 0, away: 0 },

  { category: "offsides", home: 0, away: 0 },

  { category: "fouls", home: 0, away: 0 },

  {
    category: "yellowCards",
    home: 0,
    away: 0,
  },
  { category: "redCards", home: 0, away: 0 },
  { category: "possession", home: 0, away: 0 },
];

export function processStats({ newStats, oldStats, home_team, away_team }) {
  oldStats = oldStats || defaultStats;

  let statsObj = [...oldStats];
  newStats.team.forEach((team) => {
    let home;
    if (team.team_uuid === home_team) {
      home = true;
    } else if (team.team_uuid === away_team) {
      home = false;
    }
    team.stats.forEach((stat) => {
      switch (stat.type) {
        case "totalScoringAtt":
          statsObj.find((s) => s.category === "shots")[
            home == true ? "home" : "away"
          ] = parseInt(stat.value);
          break;
        case "ontargetScoringAtt":
          statsObj.find((s) => s.category === "shotsOnTarget")[
            home == true ? "home" : "away"
          ] = parseInt(stat.value);
          break;
        case "cornerTaken":
          statsObj.find((s) => s.category === "corners")[
            home == true ? "home" : "away"
          ] = parseInt(stat.value);
          break;
        case "totalOffside":
          statsObj.find((s) => s.category === "offsides")[
            home == true ? "home" : "away"
          ] = parseInt(stat.value);
          break;
        case "fkFoulLost":
          statsObj.find((s) => s.category === "fouls")[
            home == true ? "home" : "away"
          ] = parseInt(stat.value);
          break;
        case "totalYellowCard":
          statsObj.find((s) => s.category === "yellowCards")[
            home == true ? "home" : "away"
          ] = parseInt(stat.value);
          break;
        case "totalRedCard":
          statsObj.find((s) => s.category === "redCards")[
            home == true ? "home" : "away"
          ] = parseInt(stat.value);
          break;
        case "possessionPercentage":
          statsObj.find((s) => s.category === "possession")[
            home == true ? "home" : "away"
          ] = parseFloat(stat.value);
          break;
      }
    });
  });

  return statsObj;
}
