export class TeamSponsor {
  constructor({ animate, preview, update, animateOff }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.name = "TEAM_SPONSOR";
    this.scene = "Team Sponsor";
    this.data = [];
  }

  preview({ options }) {
    this.onAir = false;
    this.data = [];

    this.sendPreview(this.scene, 500, this.data, "IN");
  }
  animate({ options }) {
    this.onAir = true;
    this.data.push({
      name: "Competition",
      value: { text: options.game?.competition?.name || "" },
    });
    this.data.push({
      name: "Competition Colour",
      value: { colour: options.game?.competition?.colour || "" },
    });
    this.data.push({
      name: "Competition Badge",
      value: { image: "${user}" + options.game?.competition?.badge || "" },
    });

    this.data.push({
      name: "Team Badge",
      value: {
        image: "${user}" + options.team?.variables?.badge || "",
      },
    });
    this.data.push({
      name: "Team Secondary Badge",
      value: {
        image: "${user}" + options.team?.variables?.alt_badge || "",
      },
    });
    let team_type =
      options?.game?.home_team?._id === options?.team?._id
        ? "home"
        : options?.game?.away_team?._id === options?.team?._id
        ? "away"
        : "";
    this.data.push({
      name: "Team Kit BG Colour",
      value: {
        colour:
          options.team?.variables?.[
            options.game?.[team_type + "_team_kit"] + "_kit_bg"
          ] || "",
      },
    });
    this.data.push({
      name: "Team Kit Text Colour",
      value: {
        colour:
          options.team?.variables?.[
            options.game?.[team_type + "_team_kit"] + "_kit_text"
          ] || "",
      },
    });

    this.data.push({
      name: "Sponsor Image",
      value: { image: "${user}" + options.sponsor_image || "" },
    });
    this.sendAnimate(this.scene, this.data);
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff(this.scene, []);
  }
}
