import React from "react";
import styled from "styled-components";
import { FaInfo } from "react-icons/fa";
import moment from "moment";
const Main = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
const Title = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  font-size: 0.7em;
`;
const InfoIcon = styled(FaInfo)`
  margin-right: 2px;
  position: absolute;
  left: 3px;
  font-size: 1.5em;
`;
const Content = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  font-size: 1.2em;
  align-items: center;
`;
export default function Opta30({ item }) {
  return (
    <Main>
      <Title>
        <InfoIcon />
        End Half
      </Title>
      <Content>{moment(item.timeStamp).format("HH:MM:SS")}</Content>
    </Main>
  );
}
