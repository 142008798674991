import moment from "moment";

export class FFPostponedSlate {
  constructor({ animate, preview, update, animateOff }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.name = "FFPostponedSlate";
    this.scene = "FFPostponedSlate";
    this.data = [];
  }

  preview({ options }) {
    this.onAir = false;
    this.data = [];

    this.data.push({
      name: "Competition",
      value: { text: options.game?.competition?.name || "" },
    });
    this.data.push({
      name: "Competition Colour",
      value: { colour: options.game?.competition?.colour || "" },
    });
    this.data.push({
      name: "Competition Badge",
      value: { image: "${user}" + options.game?.competition?.badge || "" },
    });

    this.data.push({
      name: "Home Team Name",
      value: { text: options.game?.home_team?.name || "" },
    });

    this.data.push({
      name: "Home Team Short Name",
      value: { text: options.game?.home_team?.short_name || "" },
    });

    this.data.push({
      name: "Home Team Badge",
      value: {
        image: "${user}" + options.game?.home_team?.variables?.badge,
      },
    });

    this.data.push({
      name: "Home Team Secondary Badge",
      value: {
        image: "${user}" + options.game?.home_team?.variables?.alt_badge,
      },
    });

    this.data.push({
      name: "Away Team Badge",
      value: {
        image: "${user}" + options.game?.away_team?.variables?.badge,
      },
    });

    this.data.push({
      name: "Away Team Secondary Badge",
      value: {
        image: "${user}" + options.game?.away_team?.variables?.alt_badge,
      },
    });

    this.data.push({
      name: "Home Team Video Badge",
      value: {
        video: "${user}" + options.game?.home_team?.variables?.video_badge,
      },
    });

    this.data.push({
      name: "Away Team Video Badge",
      value: {
        video: "${user}" + options.game?.away_team?.variables?.video_badge,
      },
    });

    this.data.push({
      name: "Away Team Name",
      value: { text: options.game?.away_team?.name || "" },
    });

    this.data.push({
      name: "Away Team Short Name",
      value: { text: options.game?.away_team?.short_name || "" },
    });

    this.data.push({
      name: "Home Team Kit BG Colour",
      value: {
        colour:
          options.game.home_team?.variables?.[
            options.game?.home_team_kit + "_kit_bg"
          ] || "",
      },
    });
    this.data.push({
      name: "Home Team Kit Text Colour",
      value: {
        colour:
          options.game.home_team?.variables?.[
            options.game?.home_team_kit + "_kit_text"
          ] || "",
      },
    });
    this.data.push({
      name: "Away Team Kit BG Colour",
      value: {
        colour:
          options.game.away_team?.variables?.[
            options.game?.away_team_kit + "_kit_bg"
          ] || "",
      },
    });
    this.data.push({
      name: "Away Team Kit Text Colour",
      value: {
        colour:
          options.game.away_team?.variables?.[
            options.game?.away_team_kit + "_kit_text"
          ] || "",
      },
    });

    this.data.push({
      name: "Stadium",
      value: {
        text:
          options.game?.stadium?.name || options.game?.stadium?.opta_name || "",
      },
    });
    this.data.push({
      name: "Date",
      value: {
        text: moment(options.game?.date)?.format("DD/MM/YYYY") || "",
      },
    });

    this.data.push({
      name: "Kick-Off",
      value: {
        text: moment(options?.game?.time, "HH:mmZ")?.format("HH:mm") || "",
      },
    });
    this.sendPreview(this.scene, 500, this.data, "IN");
  }
  animate({ options }) {
    this.onAir = true;

    this.sendAnimate(this.scene, this.data);
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff(this.scene, []);
  }
}
