import moment from "moment";

const CATS = {
  possessionPercentage: "Possession",
  totalScoringAtt: "Total Shots",
  ontargetScoringAtt: "On Target",
  bigChanceCreated: "Clear Cut Chances",
  cornerTaken: "Corners",
  totalOffside: "Offsides",
};

export class FFStats {
  constructor({ animate, preview, update, animateOff }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.name = "FFStats";
    this.scene = "FF Stats";
    this.data = [];
  }

  preview({ options }) {
    this.onAir = false;
    this.data = [];
    debugger;
    this.data.push({
      name: "Home Score",
      value: {
        text: options.game?.home_scorers?.length || 0,
      },
    });
    this.data.push({
      name: "Away Score",
      value: {
        text: options.game?.away_scorers?.length || 0,
      },
    });

    this.data.push({
      name: "Home Team Name",
      value: { text: options.game?.home_team?.name || "" },
    });

    this.data.push({
      name: "Home Team Badge",
      value: {
        image: "${user}" + options.game?.home_team?.variables?.badge,
      },
    });

    this.data.push({
      name: "Away Team Badge",
      value: {
        image: "${user}" + options.game?.away_team?.variables?.badge,
      },
    });

    this.data.push({
      name: "Home Team Secondary Badge",
      value: {
        image: "${user}" + options.game?.home_team?.variables?.alt_badge,
      },
    });

    this.data.push({
      name: "Away Team Badge",
      value: {
        image: "${user}" + options.game?.away_team?.variables?.badge,
      },
    });

    this.data.push({
      name: "Away Team Secondary Badge",
      value: {
        image: "${user}" + options.game?.away_team?.variables?.alt_badge,
      },
    });

    this.data.push({
      name: "Home Team Video Badge",
      value: {
        video: "${user}" + options.game?.home_team?.variables?.video_badge,
      },
    });

    this.data.push({
      name: "Away Team Video Badge",
      value: {
        video: "${user}" + options.game?.away_team?.variables?.video_badge,
      },
    });

    this.data.push({
      name: "Away Team Name",
      value: { text: options.game?.away_team?.name || "" },
    });

    this.data.push({
      name: "Competition",
      value: { text: options.game?.competition?.name || "" },
    });
    this.data.push({
      name: "Competition Colour",
      value: { colour: options.game?.competition?.colour || "" },
    });
    this.data.push({
      name: "Stadium",
      value: {
        text:
          options.game?.stadium?.name || options.game?.stadium?.opta_name || "",
      },
    });
    this.data.push({
      name: "Kick-Off",
      value: {
        text: moment(options?.game?.time, "HH:mmZ")?.format("HH:mm") || "",
      },
    });

    this.data.push({
      name: "Period",
      value: { text: options.half },
    });
    this.buildStats({
      team: "Home",
      stats: options?.game?.opta_team_stats?.home,
    });
    this.buildStats({
      team: "Away",
      stats: options?.game?.opta_team_stats?.away,
    });

    this.sendPreview(this.scene, 500, this.data, "IN");
  }

  buildStats({ team, stats, options }) {
    this.data.push({
      name: team + " " + CATS.possessionPercentage,
      value: {
        text: stats?.find((s) => s.type === "possessionPercentage")?.value || 0,
      },
    });

    this.data.push({
      name: team + " " + CATS.totalScoringAtt,
      value: {
        text: stats?.find((s) => s.type === "totalScoringAtt")?.value || 0,
      },
    });

    this.data.push({
      name: team + " " + CATS.ontargetScoringAtt,
      value: {
        text: stats?.find((s) => s.type === "ontargetScoringAtt")?.value || 0,
      },
    });

    this.data.push({
      name: team + " " + CATS.bigChanceCreated,
      value: {
        text: stats?.find((s) => s.type === "bigChanceCreated")?.value || 0,
      },
    });

    this.data.push({
      name: team + " " + CATS.cornerTaken,
      value: { text: stats?.find((s) => s.type === "cornerTaken")?.value || 0 },
    });

    this.data.push({
      name: team + " " + CATS.totalOffside,
      value: {
        text: stats?.find((s) => s.type === "totalOffside")?.value || 0,
      },
    });

    this.data.push({
      name: team + " " + CATS.totalOffside,
      value: {
        text: stats?.find((s) => s.type === "totalOffside")?.value || 0,
      },
    });

    this.data.push({
      name: team + " Successful Crosses",
      value: {
        text:
          (stats?.find((s) => s.type === "accurateCross")?.value || 0) +
          "/" +
          (stats?.find((s) => s.type === "totalCross")?.value || 0),
      },
    });

    this.data.push({
      name: team + " Successful Dribbles",
      value: {
        text:
          (stats?.find((s) => s.type === "wonContest")?.value || 0) +
          "/" +
          (stats?.find((s) => s.type === "totalContest")?.value || 0),
      },
    });

    this.data.push({
      name: team + " Red Cards",
      value: {
        text: stats?.find((s) => s.type === "totalRedCard")?.value || 0,
      },
    });

    this.data.push({
      name: team + " Yellow Cards",
      value: {
        text: stats?.find((s) => s.type === "totalYellowCard")?.value || 0,
      },
    });

    this.data.push({
      name: team + " Tackles Won",
      value: {
        text: stats?.find((s) => s.type === "wonTackle")?.value || 0,
      },
    });

    this.data.push({
      name: team + " Interceptions",
      value: {
        text: stats?.find((s) => s.type === "interception")?.value || 0,
      },
    });

    this.data.push({
      name: team + " Blocks",
      value: {
        text: stats?.find((s) => s.type === "outfielderBlock")?.value || 0,
      },
    });

    this.data.push({
      name: team + " Clearances",
      value: {
        text: stats?.find((s) => s.type === "totalClearance")?.value || 0,
      },
    });

    this.data.push({
      name: team + " Headed Clearances",
      value: {
        text: stats?.find((s) => s.type === "headClearance")?.value || 0,
      },
    });

    let aerialWon = stats?.find((s) => s.type === "aerialWon")?.value ?? 0;

    let aerialLost = stats?.find((s) => s.type === "aerialLost")?.value ?? 0;
    let aerialDuels = Math.round(
      (aerialWon / (parseInt(aerialWon) + parseInt(aerialLost))) * 100
    );

    this.data.push({
      name: team + " Aerial Duels Won",
      value: {
        text: aerialDuels,
      },
    });

    this.data.push({
      name: team + " Blocked Crosses",
      value: {
        text: stats?.find((s) => s.type === "blockedCross")?.value || 0,
      },
    });

    this.data.push({
      name: team + " Passing Accuracy",
      value: {
        text: Math.round(
          ((stats?.find((s) => s.type === "accuratePass")?.value || 0) /
            (stats?.find((s) => s.type === "totalPass")?.value || 0)) *
            100
        ),
      },
    });

    this.data.push({
      name: team + " Attacking 3rd",
      value: {
        text: Math.round(
          ((stats?.find((s) => s.type === "successfulFinalThirdPasses")
            ?.value || 0) /
            (stats?.find((s) => s.type === "totalFinalThirdPasses")?.value ||
              0)) *
            100
        ),
      },
    });

    this.data.push({
      name: team + " Key Passes",
      value: {
        text: Math.round(
          stats?.find((s) => s.type === "totalAttAssist")?.value || 0
        ),
      },
    });

    this.data.push({
      name: team + " Fouls Committed",
      value: {
        text: Math.round(
          stats?.find((s) => s.type === "fkFoulLost")?.value || 0
        ),
      },
    });

    this.data.push({
      name: team + " Fouls Won",
      value: {
        text: Math.round(
          stats?.find((s) => s.type === "fkFoulWon")?.value || 0
        ),
      },
    });

    this.data.push({
      name: team + " Yellow Cards",
      value: {
        text: Math.round(
          stats?.find((s) => s.type === "totalYellowCard")?.value || 0
        ),
      },
    });

    this.data.push({
      name: team + " Red Cards",
      value: {
        text: Math.round(
          stats?.find((s) => s.type === "totalRedCard")?.value || 0
        ),
      },
    });
  }
  animate({ options }) {
    this.onAir = true;

    this.sendAnimate(this.scene, this.data);
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff(this.scene, []);
  }
}
