import React, { useContext } from "react";
import styled from "styled-components";
import { FaInfo } from "react-icons/fa";
import { APIContext } from "../../../contexts/APIContext";
import { GraphicsContext } from "../../../contexts/GraphicsContext";
import { UIContext } from "../../../contexts/UIContext";
import moment from "moment";
const Main = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
const Title = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  font-size: 0.7em;
`;
const InfoIcon = styled(FaInfo)`
  margin-right: 2px;
  position: absolute;
  left: 3px;
  font-size: 1.5em;
`;
const Content = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  font-size: 1em;
  align-items: center;
`;

export default function LiveScore({ item, events, previewGraphic }) {
  let own_goal = item.qualifier?.findIndex((q) => q.qualifierId === 28) > -1;
  let penalty = item.qualifier?.findIndex((q) => q.qualifierId === 9) > -1;
  let home_score = events?.filter((e) => {
    return (
      e.typeId === "live_score" &&
      ((e?.contestantId === item?.game_ID?.home_team?.opta_ID &&
        e.qualifier?.find((q) => q.qualifierId === 28) === undefined) ||
        (e?.contestantId === item?.game_ID?.away_team?.opta_ID &&
          e.qualifier?.find((q) => q.qualifierId === 28) !== undefined)) &&
      moment(e?.timeStamp) - moment(item.timeStamp) <= 0
    );
  })?.length;
  let away_score = events?.filter((e) => {
    return (
      e.typeId === "live_score" &&
      ((e?.contestantId === item?.game_ID?.away_team?.opta_ID &&
        e.qualifier?.findIndex((q) => q.qualifierId === 28) === -1) ||
        (e?.contestantId === item?.game_ID?.home_team?.opta_ID &&
          e.qualifier?.findIndex((q) => q.qualifierId === 28) > -1)) &&
      moment(e?.timeStamp) - moment(item.timeStamp) <= 0
    );
  })?.length;

  return (
    <Main
      onClick={() => {
        previewGraphic("GoalUpdate", {
          game: {
            ...item?.game_ID,
            home_score,
            away_score,
            home_scorer: ((item?.contestantId ===
              item?.game_ID?.home_team?.opta_ID &&
              !own_goal) ||
              (item?.contestantId === item?.game_ID?.away_team?.opta_ID &&
                own_goal)) && {
              playerName:
                item?.playerName +
                (own_goal ? " (OG)" : "") +
                (penalty ? " (P)" : ""),
              time: getTime({ event: item }),
            },
            away_scorer: ((item?.contestantId ===
              item?.game_ID?.away_team?.opta_ID &&
              !own_goal) ||
              (item?.contestantId === item?.game_ID?.home_team?.opta_ID &&
                own_goal)) && {
              playerName:
                item?.playerName +
                (own_goal ? " (OG)" : "") +
                (penalty ? " (P)" : ""),
              time: getTime({ event: item }),
            },
          },
        });
      }}
    >
      <Title>
        <InfoIcon />
        Goal Update!
      </Title>
      <Content>
        {item?.game_ID?.home_team?.code} {home_score}-{away_score}{" "}
        {item?.game_ID?.away_team?.code}
      </Content>
    </Main>
  );
}

function getTime({ event }) {
  const periods = { 1: 45, 2: 90 };
  let mins =
    event?.timeMin + 1 > periods[event.periodId]
      ? periods[event.periodId] +
        "+" +
        (event?.timeMin - periods[event.periodId] + 1)
      : parseInt(event?.timeMin) + 1;
  return mins?.toString()?.padStart(2, "0") + "'";
}
