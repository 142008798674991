import moment from "moment";

export class LTMatchDelayed {
  constructor({ animate, preview, update, animateOff }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.name = "Match Delayed";
    this.scene = "Match Delayed";
    this.data = [];
  }

  preview({ options }) {
    this.onAir = false;
    this.options = options;

    this.data = [];

    this.data.push({
      name: "Date",
      value: {
        text: moment(options.game?.date)?.format("DD/MM/YYYY") || "",
      },
    });

    this.data.push({
      name: "Group",
      value: {
        text: options?.group?.title || "",
      },
    });

    this.data.push({
      name: "Home Team Name",
      value: { text: options.game?.home_team?.name || "" },
    });

    this.data.push({
      name: "Home Team Short Name",
      value: { text: options.game?.home_team?.short_name || "" },
    });

    this.data.push({
      name: "Away Team Name",
      value: { text: options.game?.away_team?.name || "" },
    });
    this.data.push({
      name: "Away Team Short Name",
      value: { text: options.game?.away_team?.short_name || "" },
    });
    this.data.push({
      name: "Home Team Badge",
      value: {
        image: "${user}" + options.game?.home_team?.variables?.badge,
      },
    });

    this.data.push({
      name: "Home Team Secondary Badge",
      value: {
        image: "${user}" + options.game?.home_team?.variables?.alt_badge,
      },
    });

    this.data.push({
      name: "Away Team Badge",
      value: {
        image: "${user}" + options.game?.away_team?.variables?.badge,
      },
    });

    this.data.push({
      name: "Away Team Secondary Badge",
      value: {
        image: "${user}" + options.game?.away_team?.variables?.alt_badge,
      },
    });

    this.data.push({
      name: "Home Team Kit BG Colour",
      value: {
        colour:
          options.game.home_team?.variables?.[
            options.game?.home_team_kit + "_kit_bg"
          ] || "",
      },
    });
    this.data.push({
      name: "Home Team Kit Text Colour",
      value: {
        colour:
          options.game.home_team?.variables?.[
            options.game?.home_team_kit + "_kit_text"
          ] || "",
      },
    });
    this.data.push({
      name: "Away Team Kit BG Colour",
      value: {
        colour:
          options.game.away_team?.variables?.[
            options.game?.away_team_kit + "_kit_bg"
          ] || "",
      },
    });
    this.data.push({
      name: "Away Team Kit Text Colour",
      value: {
        colour:
          options.game.away_team?.variables?.[
            options.game?.away_team_kit + "_kit_text"
          ] || "",
      },
    });

    this.data.push({
      name: "Home Score",
      value: {
        text: options.game?.home_scorers?.length || 0,
      },
    });
    this.data.push({
      name: "Away Score",
      value: {
        text: options.game?.away_scorers?.length || 0,
      },
    });
    this.data.push({
      name: "Competition",
      value: { text: options.game?.competition?.name || "" },
    });

    this.data.push({
      name: "Competition Badge",
      value: { image: "${user}" + options.game?.competition?.badge || "" },
    });

    this.data.push({
      name: "Competition Colour",
      value: { colour: options.game?.competition?.colour || "" },
    });

    this.data.push({
      name: "Stadium",
      value: {
        text:
          options.game?.stadium?.name || options.game?.stadium?.opta_name || "",
      },
    });

    this.data.push({
      name: "Comm First Name 1",
      value: { text: options.game?.commentators?.[0]?.first_name || "" },
    });

    this.data.push({
      name: "Comm Last Name 1",
      value: { text: options.game?.commentators?.[0]?.last_name || "" },
    });

    this.data.push({
      name: "Comm Full Name 1",
      value: {
        text:
          (options.game?.commentators?.[0]?.first_name || "") +
          " " +
          (options.game?.commentators?.[0]?.last_name || ""),
      },
    });

    this.data.push({
      name: "Comm First Name 2",
      value: { text: options.game?.commentators?.[1]?.first_name || "" },
    });

    this.data.push({
      name: "Comm Last Name 2",
      value: { text: options.game?.commentators?.[1]?.last_name || "" },
    });

    this.data.push({
      name: "Comm Full Name 2",
      value: {
        text:
          (options.game?.commentators?.[1]?.first_name || "") +
          " " +
          (options.game?.commentators?.[1]?.last_name || ""),
      },
    });

    this.data.push({
      name: "Comm First Name 3",
      value: { text: options.game?.commentators?.[2]?.first_name || "" },
    });

    this.data.push({
      name: "Comm Last Name 3",
      value: { text: options.game?.commentators?.[2]?.last_name || "" },
    });

    this.data.push({
      name: "Comm Full Name 3",
      value: {
        text:
          (options.game?.commentators?.[2]?.first_name || "") +
          " " +
          (options.game?.commentators?.[2]?.last_name || ""),
      },
    });
    // let scorer;

    // if (options.show_scorers) {
    //   let scorers_sorted = [
    //     ...options.game.live_data.home_scorers.map((s) => {
    //       return { ...s, team: "home" };
    //     }),
    //     ...options.game.live_data.away_scorers.map((s) => {
    //       return { ...s, team: "away" };
    //     }),
    //   ];

    //   scorers_sorted = scorers_sorted.sort((a, b) => b.time - a.time);
    //   if (scorers_sorted.length > 0) {
    //     scorer = scorers_sorted[0];
    //   }
    // }
    // debugger;
    // this.data = [
    //   {
    //     name: "TopTEXT",
    //     value: { text: options.game.competition.name.toUpperCase() },
    //   },
    //   {
    //     name: "Home Team",
    //     value: { text: options.game.home_team.variables["name_uppercase"] },
    //   },
    //   {
    //     name: "Away Team",
    //     value: { text: options.game.away_team.variables["name_uppercase"] },
    //   },
    //   { name: "STADIUM_TEXT", value: { text: options.game.stadium.name } },
    //   options.game.home_team.variables["badge_home"] && {
    //     name: "Home Badge IMG",
    //     value: {
    //       image:
    //         "http://localhost:6060/uploads/MAN CITY/" +
    //         window.encodeURIComponent(
    //           "Badges/" + options.game.home_team.variables["badge_home"]
    //         ),
    //     },
    //   },
    //   options.game.away_team.variables["badge_away"] && {
    //     name: "Away Badge IMG",
    //     value: {
    //       image:
    //         "http://localhost:6060/uploads/MAN CITY/" +
    //         window.encodeURIComponent(
    //           "Badges/" + options.game.away_team.variables["badge_away"]
    //         ),
    //     },
    //   },
    //   {
    //     name: "Home Score",
    //     value: {
    //       text:
    //         (options.game.live_data &&
    //           options.game.live_data.home_team_score) ||
    //         0,
    //     },
    //   },
    //   {
    //     name: "Away Score",
    //     value: {
    //       text:
    //         (options.game.live_data &&
    //           options.game.live_data.away_team_score) ||
    //         0,
    //     },
    //   },
    //   {
    //     name: "VENUE",
    //     value: { text: options.game?.stadium?.name || "" },
    //   },
    //   {
    //     name: "COMMENTATORS",
    //     value: { text: options.game?.commentators || "" },
    //   },
    //   { name: "HALF_TIME", value: { visible: true } },
    //   {
    //     name: "HOME_SCORE_GRP",
    //     value: {
    //       visible:
    //         options.show_scorers && scorer && scorer.team === "home"
    //           ? true
    //           : false,
    //     },
    //   },
    //   {
    //     name: "AWAY_SCORE_GRP",
    //     value: {
    //       visible:
    //         options.show_scorers && scorer && scorer.team === "away"
    //           ? true
    //           : false,
    //     },
    //   },
    // ];

    // if (options.show_scorers && scorer) {
    //   let player = options.game.live_data.home_team_lineup.find(
    //     (l) => l._id === scorer.player
    //   );
    //   if (!player) {
    //     player = options.game.live_data.away_team_lineup.find(
    //       (l) => l._id === scorer.player
    //     );
    //   }
    //   let time = moment.duration(scorer.time);
    //   this.data.push({
    //     name: scorer.team.toUpperCase() + "_SCORER",
    //     value: {
    //       text:
    //         player.first_name[0] +
    //         ". " +
    //         player.last_name +
    //         " " +
    //         (time.minutes() + time.hours() * 60) +
    //         "' " +
    //         (scorer.type ? scorer.type : ""),
    //     },
    //   });
    // }

    this.sendPreview(this.scene, 500, this.data, "IN");
  }

  animate({ options }) {
    this.onAir = true;
    this.sendAnimate(this.scene, this.data);
  }
  animateOff({ options }) {
    clearTimeout(this.to);
    this.onAir = false;
    this.sendAnimateOff(this.scene, []);
  }
}
