import React from "react";
import styled from "styled-components";
import { FaChevronUp } from "react-icons/fa";
const Main = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
const Title = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  font-size: 0.7em;
`;
const SubIcon = styled(FaChevronUp)`
  margin-right: 2px;
  position: absolute;
  left: 3px;
  color: #0adb93;
  font-size: 1.5em;
`;
const Content = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  font-size: 1.2em;
  align-items: center;
`;

export default function Opta18({
  item,
  player,
  playerOff,
  previewGraphic,
  game,
  team,
}) {
  function preview() {
    let player_off = game?.lineup?.home?.find(
      (p) => p.squad?.player?.opta_ID === playerOff?.playerId
    );
    if (!player_off) {
      player_off = game?.lineup?.away?.find(
        (p) => p.squad?.player?.opta_ID === playerOff?.playerId
      );
    }

    previewGraphic("Substitution", {
      players: [player, player_off],
      team: team,
      game: game,
      clock: true,
    });
  }

  return (
    <Main
      onClick={(e) => {
        preview();
      }}
    >
      <Title>
        <SubIcon />
        Player On
      </Title>
      <Content>
        {player?.squad?.shirt_number ?? player?.squad?.opta_shirt_number}.{" "}
        {player?.squad?.player?.last_name ??
          player?.squad?.player?.opta_last_name}
      </Content>
    </Main>
  );
}
