export class Commentators {
  constructor({ animate, preview, update, animateOff }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.name = "Commentators";
    this.scene = "Commentators";
    this.data = [];
  }

  preview({ options }) {
    this.onAir = false;
    this.data = [];

    this.data.push({
      name: "Competition",
      value: { text: options.game?.competition?.name || "" },
    });
    this.data.push({
      name: "Competition Colour",
      value: { colour: options.game?.competition?.colour || "" },
    });
    this.data.push({
      name: "Competition Badge",
      value: { image: "${user}" + options.game?.competition?.badge || "" },
    });

    for (let x = 0; x < options.commentators?.length; x++) {
      let comm = options.commentators[x];

      this.data.push({
        name: "Commentator First Name " + (x + 1),
        value: {
          text: comm.first_name ?? "",
        },
      });
      this.data.push({
        name: "Commentator Last Name " + (x + 1),
        value: {
          text: comm.last_name ?? "",
        },
      });
      this.data.push({
        name: "Commentator First Name Uppercase " + (x + 1),
        value: {
          text: comm.first_name?.toUpperCase() ?? "",
        },
      });
      this.data.push({
        name: "Commentator Last Name Uppercase " + (x + 1),
        value: {
          text: comm.last_name?.toUpperCase() ?? "",
        },
      });
      this.data.push({
        name: "Commentator Full Name " + (x + 1),
        value: {
          text: (comm.first_name ?? "") + " " + (comm.last_name ?? ""),
        },
      });
      this.data.push({
        name: "Commentator Full Name Uppercase " + (x + 1),
        value: {
          text:
            (comm.first_name?.toUpperCase() ?? " ") +
            (comm.last_name?.toUpperCase() ?? ""),
        },
      });
    }

    this.sendPreview(this.scene, 500, this.data, "IN");
  }
  animate({ options }) {
    this.onAir = true;

    this.sendAnimate(this.scene, this.data);
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff(this.scene, []);
  }
}
