export class FFSponsor {
  constructor({ animate, preview, update, animateOff }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.name = "FFSponsor";
    this.scene = "FFSponsor";
    this.data = [];
  }

  preview({ options }) {
    this.onAir = false;
    this.data = [];

    this.data.push({
      name: "Competition",
      value: { text: options.game?.competition?.name || "" },
    });
    this.data.push({
      name: "Competition Colour",
      value: { colour: options.game?.competition?.colour || "" },
    });
    this.data.push({
      name: "Competition Badge",
      value: { image: "${user}" + options.game?.competition?.badge || "" },
    });
    this.data.push({
      name: "Home Team Name",
      value: {
        text: options.game?.home_team?.name || "",
      },
    });
    this.data.push({
      name: "Home Team Sponsor Image",
      value: {
        image:
          "${user}" + options.game?.home_team?.variables?.slate_sponsor || "",
      },
    });
    this.data.push({
      name: "Away Team Name",
      value: {
        text: options.game?.away_team?.name || "",
      },
    });
    this.data.push({
      name: "Away Team Sponsor Image",
      value: {
        image:
          "${user}" + options.game?.away_team?.variables?.slate_sponsor || "",
      },
    });

    this.sendPreview(this.scene, 500, this.data, "IN");
  }
  animate({ options }) {
    this.onAir = true;

    this.sendAnimate(this.scene, this.data);
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff(this.scene, []);
  }
}
