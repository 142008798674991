import React, { useContext } from "react";
import styled from "styled-components";
import { UIContext, VIEW_CONSTS } from "../../contexts/UIContext";
import { APIContext } from "../../contexts/APIContext";
import useLocalStorage from "../../Hooks/useLocalStorage";

const TeamNamePanel = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 1em;
  align-items: center;
  box-sizing: border-box;
  justify-content: center;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  background-color: #181d25;
  height: 7vh;
`;
const Team = styled.div`
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  display: flex;
  width: 40%;
  align-items: center;
  height: 54px;
`;
const Middle = styled.div`
  display: flex;
  width: 20%;
  justify-content: space-evenly;
  align-items: center;
  margin-top: -10px;
`;
const TeamName = styled.div`
  padding-left: ${(props) => (props.home ? "0.5em" : "")};
  margin-left: ${(props) => (props.home ? "auto" : "")};
  display: flex;
  font-size: 1.5em;
  cursor: pointer;
`;
const Score = styled.div`
  display: flex;
  font-size: 4em;
  width: 100%;
  justify-content: center;
  cursor: pointer;
  border-radius: 2px;
  :hover {
    background-color: #db0a41;
  }
`;

const Red = styled.div`
  display: flex;
  width: 40px;
  height: 20px;
  background-color: ${({ selected }) => (selected ? "#d93232" : "")};
  border: 1px solid #d93232;
  margin: 5px;
  border-radius: 2px;
  cursor: pointer;
  :hover {
    background-color: #d93232;
  }
`;
export default function TeamNames({
  setGoalScored,
  game,
  setSelectedPlayer,
  group,
}) {
  const { setView, selectedTeam, setGoal } = useContext(UIContext);
  const { updateGame } = useContext(APIContext);
  const [homeReds, setHomeReds] = useLocalStorage(game?._id + "home_reds");
  const [awayReds, setAwayReds] = useLocalStorage(game?._id + "away_reds");

  let homeBGColour =
    game.home_team?.variables?.[game?.home_team_kit + "_kit_bg"] || "";
  let awayBGColour =
    game.away_team?.variables?.[game?.away_team_kit + "_kit_bg"] || "";

  return (
    <TeamNamePanel>
      <Team
        style={{
          borderBottom:
            "3px solid " + (homeBGColour ? homeBGColour : "rgba(0,0,0,0)"),
          opacity:
            !selectedTeam || selectedTeam._id === game.home_team._id
              ? "1"
              : ".2",
        }}
      >
        <div>{game.home_formation?.name}</div>
        {(!game?.lineup?.home || game?.lineup?.home?.length === 0) && (
          <>
            <Red
              selected={homeReds?.find((r) => r.game === game._id)?.one}
              onContextMenu={(e) => {
                e.preventDefault();
              }}
              onMouseDown={(e) => {
                let new_reds = [...(homeReds || [])];
                if (new_reds?.findIndex((r) => r.game === game._id) === -1) {
                  new_reds.push({ game: game._id });
                }
                let old_reds = new_reds?.find((r) => r.game === game._id);
                switch (e.button) {
                  case 0:
                    old_reds.one = true;
                    setHomeReds(new_reds);
                    break;
                  case 2:
                    old_reds.one = false;

                    setHomeReds(new_reds);
                    break;
                  default:
                    break;
                }
              }}
            />
            <Red
              selected={homeReds?.find((r) => r.game === game._id)?.two}
              onContextMenu={(e) => {
                e.preventDefault();
              }}
              onMouseDown={(e) => {
                let new_reds = [...(homeReds || [])];
                if (new_reds?.findIndex((r) => r.game === game._id) === -1) {
                  new_reds.push({ game: game._id });
                }
                let old_reds = new_reds?.find((r) => r.game === game._id);
                switch (e.button) {
                  case 0:
                    old_reds.two = true;
                    setHomeReds(new_reds);
                    break;
                  case 2:
                    old_reds.two = false;

                    setHomeReds(new_reds);
                    break;
                  default:
                    break;
                }
              }}
            />
            <Red
              selected={homeReds?.find((r) => r.game === game._id)?.three}
              onContextMenu={(e) => {
                e.preventDefault();
              }}
              onMouseDown={(e) => {
                let new_reds = [...(homeReds || [])];
                if (new_reds?.findIndex((r) => r.game === game._id) === -1) {
                  new_reds.push({ game: game._id });
                }
                let old_reds = new_reds?.find((r) => r.game === game._id);
                switch (e.button) {
                  case 0:
                    old_reds.three = true;
                    setHomeReds(new_reds);
                    break;
                  case 2:
                    old_reds.three = false;

                    setHomeReds(new_reds);
                    break;
                  default:
                    break;
                }
              }}
            />
          </>
        )}
        <TeamName home>{game.home_team.name}</TeamName>
      </Team>
      <Middle>
        <Score
          onContextMenu={(e) => {
            e.preventDefault();
          }}
          onMouseDown={(e) => {
            setView(1);
            setSelectedPlayer();

            switch (e.button) {
              case 0:
                let periods = [...(game.live_data?.periods || [])];
                let period = periods.sort((a, b) => b.number - a.number)[0];
                let time = period?.kick_off ? Date.now() - period?.kick_off : 0;
                if (period.number === 2) {
                  time += 1000 * 60 * (game?.config?.half_duration || 45);
                }
                if (period.number === 3) {
                  time += 1000 * 60 * ((game?.config?.half_duration || 45) * 2);
                }
                if (period.number === 4) {
                  time += 1000 * 60 * 105;
                }
                let _id = Date.now();

                if (
                  (game?.lineup?.home && game?.lineup?.home?.length > 0) ||
                  (game?.opta_lineup?.home &&
                    game?.opta_lineup?.home?.length > 0)
                ) {
                  setGoal({
                    team: game.home_team,
                    time: time + 1000 * 60,
                    _id: _id,
                  });
                }

                updateGame({
                  id: game._id,
                  home_scorers: [...game.home_scorers, { time: time, _id }],
                });
                break;
              case 2:
                if (game?.home_scorers?.length - 1 >= 0) {
                  let newScorers = [...game.home_scorers];
                  if (game.away_scorers && game.away_scorers.length > 0) {
                    newScorers = newScorers.concat(
                      game.away_scorers.filter((g) => g.own_goal)
                    );
                  }

                  let latestGoals = newScorers.sort((a, b) => {
                    return b.time - a.time;
                  });
                  if (latestGoals.length > 0) {
                    newScorers = game.home_scorers.filter(
                      (g) => g !== latestGoals[0]
                    );
                    updateGame({
                      id: game._id,
                      home_scorers: newScorers,
                    });
                  }
                }
                break;
              default:
                break;
            }
          }}
        >
          {game?.home_scorers?.length || 0}
        </Score>
        -
        <Score
          right
          onContextMenu={(e) => {
            e.preventDefault();
          }}
          onMouseDown={(e) => {
            setView(1);
            setSelectedPlayer();
            switch (e.button) {
              case 0:
                let periods = [...(game.live_data?.periods || [])];
                let period = periods.sort((a, b) => b.number - a.number)[0];
                let time = period?.kick_off ? Date.now() - period?.kick_off : 0;
                if (period.number === 2) {
                  time += 1000 * 60 * (game?.config?.half_duration || 45);
                }
                if (period.number === 3) {
                  time += 1000 * 60 * ((game?.config?.half_duration || 45) * 2);
                }
                if (period.number === 4) {
                  time += 1000 * 60 * 105;
                }
                let _id = Date.now();
                if (
                  (game?.lineup?.away && game?.lineup?.away?.length > 0) ||
                  (game?.opta_lineup?.away &&
                    game?.opta_lineup?.away?.length > 0)
                ) {
                  setGoal({
                    team: game.away_team,
                    time: time + 1000 * 60,
                    _id: _id,
                  });
                }
                updateGame({
                  id: game._id,
                  away_scorers: [...game.away_scorers, { time: time, _id }],
                });
                break;
              case 2:
                if (game?.away_scorers?.length - 1 >= 0) {
                  let newScorers = [...game.away_scorers];
                  if (game.home_scorers && game.home_scorers.length > 0) {
                    newScorers = newScorers.concat(
                      game.home_scorers.filter((g) => g.own_goal)
                    );
                  }

                  let latestGoals = newScorers.sort((a, b) => {
                    return b.time - a.time;
                  });
                  if (latestGoals.length > 0) {
                    newScorers = game.away_scorers.filter(
                      (g) => g !== latestGoals[0]
                    );
                    updateGame({
                      id: game._id,
                      away_scorers: newScorers,
                    });
                  }
                }
                break;
              default:
                break;
            }
          }}
        >
          {game?.away_scorers?.length || 0}
        </Score>
      </Middle>
      <Team
        style={{
          borderBottom:
            "3px solid " + (awayBGColour ? awayBGColour : "rgba(0,0,0,0)"),
          opacity:
            !selectedTeam || selectedTeam._id === game.away_team._id
              ? "1"
              : ".2",
        }}
      >
        <TeamName
        // onClick={() => {
        //   if (selectedTeam && selectedTeam._id === game.away_team._id) {
        //     setSelectedTeam();
        //     setView(VIEW_CONSTS.live);
        //   } else {
        //     setSelectedTeam(game.away_team);
        //     if (view === VIEW_CONSTS.live) {
        //       setView(VIEW_CONSTS.formation);
        //     }
        //   }
        // }}
        >
          {game.away_team.name}
        </TeamName>
        <div style={{ marginLeft: "auto" }}>{game.away_formation?.name}</div>
        {(!game?.lineup?.away || game?.lineup?.away?.length === 0) && (
          <>
            <>
              <Red
                selected={awayReds?.find((r) => r.game === game._id)?.one}
                onContextMenu={(e) => {
                  e.preventDefault();
                }}
                onMouseDown={(e) => {
                  let new_reds = [...(awayReds || [])];
                  if (new_reds?.findIndex((r) => r.game === game._id) === -1) {
                    new_reds.push({ game: game._id });
                  }
                  let old_reds = new_reds?.find((r) => r.game === game._id);
                  switch (e.button) {
                    case 0:
                      old_reds.one = true;
                      setAwayReds(new_reds);
                      break;
                    case 2:
                      old_reds.one = false;

                      setAwayReds(new_reds);
                      break;
                    default:
                      break;
                  }
                }}
              />
              <Red
                selected={awayReds?.find((r) => r.game === game._id)?.two}
                onContextMenu={(e) => {
                  e.preventDefault();
                }}
                onMouseDown={(e) => {
                  let new_reds = [...(awayReds || [])];
                  if (new_reds?.findIndex((r) => r.game === game._id) === -1) {
                    new_reds.push({ game: game._id });
                  }
                  let old_reds = new_reds?.find((r) => r.game === game._id);
                  switch (e.button) {
                    case 0:
                      old_reds.two = true;
                      setAwayReds(new_reds);
                      break;
                    case 2:
                      old_reds.two = false;

                      setAwayReds(new_reds);
                      break;
                    default:
                      break;
                  }
                }}
              />
              <Red
                selected={awayReds?.find((r) => r.game === game._id)?.three}
                onContextMenu={(e) => {
                  e.preventDefault();
                }}
                onMouseDown={(e) => {
                  let new_reds = [...(awayReds || [])];
                  if (new_reds?.findIndex((r) => r.game === game._id) === -1) {
                    new_reds.push({ game: game._id });
                  }
                  let old_reds = new_reds?.find((r) => r.game === game._id);
                  switch (e.button) {
                    case 0:
                      old_reds.three = true;
                      setAwayReds(new_reds);
                      break;
                    case 2:
                      old_reds.three = false;

                      setAwayReds(new_reds);
                      break;
                    default:
                      break;
                  }
                }}
              />
            </>
          </>
        )}
      </Team>
    </TeamNamePanel>
  );
}
