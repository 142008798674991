import React, { useContext, useEffect, useState } from "react";
import HyperLogo from "../../Images/HyperLogo.png";
import styled from "styled-components";
import Clock from "./Clock";
import Player from "./Player";
import Subs from "../Subs/Subs";
import EndHalf from "./EndHalf";
import moment from "moment";
import { Button } from "../../Theme/Hyper";
import { UserContext } from "../../contexts/UserContext";
import Select from "react-select";
import { ControlContext } from "../../contexts/ControlContext";
import { APIContext } from "../../contexts/APIContext";
import { ScrollBar } from "../../Theme/Hyper";
const Scroll = styled(ScrollBar)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  overflow-y: auto;
  @media only screen and (max-height: 1080px) {
    height: 400px;
  }
  @media only screen and (min-height: 1080px) and (max-height: 1440px) {
    height: 800px;
  }
`;

const Main = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding-top: 0.5em;
  background-color: #202731;
  box-sizing: border-box;
  flex-direction: column;
  position: relative;
`;
const Logo = styled.img`
  height: auto;
  width: 50%;
`;
const GraphicButton = styled.div`
  display: flex;
  align-items: center;
  background-color: ${(props) =>
    props.green ? "#0adb93" : props.red ? "#db0a41" : "#181d25"};
  color: ${(props) => (props.green ? "#1d1d1d" : "")};
  min-height: 50px;
  width: 90%;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 1em;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  :hover {
    background-color: ${(props) =>
      props.green ? "#0adb93" : props.red ? "#db0a41" : "#db0a41"};
  }
`;
const CurrentGraphic = styled.div`
  margin-top: auto;
  width: 100%;
  height: 265px;
  background-color: #181d25;
  box-sizing: border-box;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-top: 0.5em;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  position: absolute;
  bottom: 12px;
  margin: 0px;
  padding-bottom: 10px;
`;
const Buttons = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;
const NavButton = styled(Button)`
  margin: 0.5em;
  background-color: ${(props) => (props.selected ? "#0adb93" : "")};
  color: ${(props) => (props.selected ? "#1d1d1d" : "")};
`;

const Profile = styled.div`
  width: 100%;
  height: 35px;
  background-color: #202731;
  z-index: 1000;
  position: -webkit-sticky;
  position: sticky;
  box-shadow: 0px 4px 12px -20px rgb(0 0 0 / 20%),
    0px 8px 20px 2px rgb(0 0 0 / 14%), 0 9px 46px 8px rgb(0 0 0 / 12%);
  display: flex;
  align-items: center;
  border-bottom: 1px solid #030304;
  padding-left: 5px;
  padding-right: 5px;
  box-sizing: border-box;
  padding-bottom: 5px;
  margin-bottom: 10px;
`;

const SelectContainer = styled(Select)`
  min-width: 200px;
  margin-bottom: 0.5em;
  width: 90%;
`;

const DropdownStyles = {
  menu: (provided) => ({
    ...provided,
    backgroundColor: "#0e1219",
  }),
  option: (provided, state) => {
    return {
      ...provided,
      backgroundColor: "#0e1219",
      "&:hover": {
        color: "#db0a41",
      },
    };
  },
  control: (provided) => ({
    ...provided,
    backgroundColor: "#0e1219",
    borderColor: "#384454",
  }),
  valueContainer: (provided) => ({
    ...provided,
    backgroundColor: "#0e1219",
    borderColor: "#384454",
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition, color: "#fff" };
  },
};

export default function RightSideBar({
  game,
  updateGame,
  previewGraphic,
  graphicInPreview,
  animateGraphic,
  animateOffGraphic,
  updateGraphic,
  teams,
  selectedPlayer,
  players,
  stadiums,
  sky,
  squads,
  countries,
  standings,
  kits,
  clearPreview,
  homeManager,
  awayManager,
  groups,
  group,
}) {
  const { user, signOut } = useContext(UserContext);
  const { engines, selectedEngine, setSelectedEngine } =
    useContext(ControlContext);
  const { getGroup, getFixtures, getStandings } = useContext(APIContext);
  const [view, setView] = useState(0);
  let period = [...(game?.live_data?.periods || [])].sort(
    (a, b) => b.number - a.number
  )[0];

  let latestGoal =
    game && game.live_data?.home_scorers ? game.live_data?.home_scorers : [];

  latestGoal = latestGoal
    .concat(game.live_data?.away_scorers || [])
    .sort((a, b) => b.time - a.time);
  if (latestGoal.length > 0) {
    latestGoal = latestGoal[0].time;
  }
  let time = moment.duration(latestGoal);
  time =
    time.minutes() +
    time.hours() * 60 +
    ":" +
    time.seconds().toString().padStart(2, "0");

  return (
    <Main>
      {!window.hideHeader && (
        <Profile>
          {user?.username}
          <Button
            style={{ border: "1px solid #fff", marginLeft: "auto" }}
            onClick={() => {
              signOut();
            }}
          >
            Sign Out
          </Button>
        </Profile>
      )}
      {engines?.length > 1 && (
        <SelectContainer
          styles={DropdownStyles}
          setSize={true}
          value={{
            value: selectedEngine,
            label: engines?.find((e) => e.key === selectedEngine)?.name,
          }}
          options={engines.map((item, index) => {
            return {
              value: item.key,
              label: item.name,
            };
          })}
          onChange={(e) => {
            setSelectedEngine(e.value);
          }}
          placeholder="Round"
        />
      )}
      <Logo src={HyperLogo}></Logo>
      <Clock
        game={game}
        teams={teams}
        updateGame={updateGame}
        previewGraphic={previewGraphic}
        animateGraphic={animateGraphic}
        animateOffGraphic={animateOffGraphic}
        updateGraphic={updateGraphic}
        sky={sky}
      />
      <Buttons>
        <NavButton selected={view === 0} onClick={() => setView(0)}>
          MAIN
        </NavButton>
        <NavButton selected={view === 1} onClick={() => setView(1)}>
          SUBS
        </NavButton>
      </Buttons>

      {selectedPlayer && view === 0 && (
        <Player
          player={selectedPlayer}
          game={game}
          previewGraphic={previewGraphic}
          teams={teams}
          sky={sky}
          countries={countries}
          period={period}
        />
      )}
      {view === 1 &&
        (game.lineup?.home?.filter((p) => p.sub_order).length > 0 ||
          game.lineup?.away?.filter((p) => p.sub_order).length > 0) && (
          <Subs
            sky={sky}
            countries={countries}
            previewGraphic={previewGraphic}
            game={game}
            teams={teams}
            players={players}
            homeSubs={game.lineup?.home?.filter((p) => p.sub_order)}
            awaySubs={game.lineup?.away?.filter((p) => p.sub_order)}
          />
        )}
      {view === 0 && (
        <Scroll>
          {!selectedPlayer &&
            period &&
            !period.kick_off &&
            period.number === 2 && (
              <>
                <GraphicButton
                  onClick={() => {
                    previewGraphic("Score", {
                      game: game,
                      type: "HT",
                      group,
                      period,
                    });
                  }}
                >
                  HT Score
                </GraphicButton>

                {game?.opta_team_stats?.home && (
                  <GraphicButton
                    onClick={() => {
                      previewGraphic("FFStats", {
                        game: game,

                        half: 1,
                      });
                    }}
                  >
                    HT Stats
                  </GraphicButton>
                )}
              </>
            )}
          {!selectedPlayer &&
            period &&
            !period.kick_off &&
            period.number === 3 &&
            game?.competition?.competition_type === "beach" && (
              <GraphicButton
                onClick={() => {
                  previewGraphic("Score", {
                    game: game,
                    type: "HT",
                    group,
                    period,
                  });
                }}
              >
                HT Score
              </GraphicButton>
            )}

          {!selectedPlayer &&
            game?.competition?.competition_type === "beach" &&
            period.kick_off === null &&
            period.number > 1 && (
              <GraphicButton
                onClick={() => {
                  previewGraphic("Score", {
                    game: game,
                    teams: teams,
                    sky: sky,
                    half: 2,
                    show_half: false,
                    group,
                  });
                }}
              >
                Score
              </GraphicButton>
            )}
          {!selectedPlayer &&
            game?.competition?.competition_type === "beach" &&
            period.kick_off === null &&
            period.number > 1 && (
              <GraphicButton
                onClick={() => {
                  previewGraphic("Score", {
                    game: game,
                    type: "FT",
                    group,
                    period,
                  });
                }}
              >
                FF Score
              </GraphicButton>
            )}
          {!selectedPlayer &&
            period &&
            !period.kick_off &&
            period.number > (game?.config?.number_of_periods || 2) &&
            game?.competition?.competition_type !== "beach" && (
              <>
                <GraphicButton
                  onClick={() => {
                    previewGraphic("Score", {
                      game: game,
                      type: "FT",
                      group,
                      period,
                    });
                  }}
                >
                  FT Score
                </GraphicButton>

                <GraphicButton
                  onClick={() => {
                    previewGraphic("Score", {
                      game: game,
                      teams: teams,
                      sky: sky,
                      half: 2,
                      show_half: false,
                      group,
                      period,
                    });
                  }}
                >
                  Score
                </GraphicButton>
                {game?.opta_team_stats?.home && (
                  <GraphicButton
                    onClick={() => {
                      previewGraphic("FFStats", {
                        game: game,
                        half: 2,
                      });
                    }}
                  >
                    FT Stats
                  </GraphicButton>
                )}
              </>
            )}

          {!selectedPlayer &&
            period &&
            !period.kick_off &&
            period.number >
              game?.config?.number_of_periods + game?.config?.number_of_et && (
              <GraphicButton
                onClick={() => {
                  previewGraphic("Score", {
                    game: game,
                    type: "PENS",
                    group,
                    period,
                  });
                }}
              >
                Score After Penalties
              </GraphicButton>
            )}

          {!selectedPlayer &&
            period &&
            !period.kick_off &&
            period.number === (game?.config?.number_of_periods || 2) + 1 &&
            game?.competition?.competition_type !== "beach" && (
              <>
                <GraphicButton
                  onClick={() => {
                    previewGraphic("Score", {
                      game: game,
                      type: "ETHT",
                      group,
                      period,
                    });
                  }}
                >
                  ET HT Score
                </GraphicButton>
                <GraphicButton
                  onClick={() => {
                    previewGraphic("Score", {
                      game: game,
                      teams: teams,
                      sky: sky,
                      half: period.number,
                      show_half: false,
                      group,
                      period,
                    });
                  }}
                >
                  Score
                </GraphicButton>
              </>
            )}

          {!selectedPlayer &&
            period &&
            !period.kick_off &&
            period.number === 5 && (
              <>
                <GraphicButton
                  onClick={() => {
                    previewGraphic("Score", {
                      game: game,
                      type: "ETFT",
                      group,
                      period,
                    });
                  }}
                >
                  ET FT Score
                </GraphicButton>
              </>
            )}

          {!selectedPlayer && period && period.number === 1 && (
            <GraphicButton
              onClick={() => {
                previewGraphic("Versus", {
                  game: game,
                  teams: teams,
                  sky: sky,
                  group,
                });
              }}
            >
              Versus
            </GraphicButton>
          )}

          {!selectedPlayer && !period?.kick_off && period?.number === 1 && (
            <GraphicButton
              onClick={() => {
                previewGraphic("MatchID", {
                  game: game,
                  teams: teams,
                  stadiums: stadiums,
                  sky: sky,
                  group,
                });
              }}
            >
              Match ID
            </GraphicButton>
          )}

          {game.live_data &&
            game.live_data.home_team_lineup &&
            game.live_data.away_team_lineup &&
            !selectedPlayer &&
            period &&
            !period.kick_off &&
            period.number === 1 && (
              <>
                <GraphicButton
                  onClick={() => {
                    previewGraphic("FFFormation", {
                      game: game,
                      team: "home",
                      players: players,
                      countries: countries,
                      squads: squads.filter(
                        (s) => s.season._id === game.season._id
                      ),
                    });
                  }}
                >
                  Home FF Formation
                </GraphicButton>
                <GraphicButton
                  onClick={() => {
                    previewGraphic("FFFormation", {
                      game: game,
                      team: "away",
                      players: players,
                      countries: countries,
                      squads: squads.filter(
                        (s) => s.season._id === game.season._id
                      ),
                    });
                  }}
                >
                  Away FF Formation
                </GraphicButton>
              </>
            )}

          {!selectedPlayer && period && period.kick_off && (
            <GraphicButton
              onClick={() => {
                previewGraphic("Score", {
                  game: game,
                  teams: teams,
                  group,
                  period,
                });
              }}
            >
              Score
            </GraphicButton>
          )}
          {!selectedPlayer && period && period.kick_off && (
            <GraphicButton
              onClick={() => {
                previewGraphic("Score", {
                  game: game,
                  teams: teams,
                  sky: sky,
                  half: 2,
                  show_half: false,
                  no_scorers: true,
                  group,
                  period,
                });
              }}
            >
              Score (No Scorers)
            </GraphicButton>
          )}
          {game?.competition?.competition_type !== "beach" &&
            !selectedPlayer && (
              <GraphicButton
                onClick={() => {
                  previewGraphic("TeamTicker", {
                    game: game,
                    teams: teams,
                    players: players,
                    team: game.home_team,
                    squads: game.lineup?.home,
                    start: true,
                  });
                }}
              >
                Home Team Ticker
              </GraphicButton>
            )}

          {game?.competition?.competition_type !== "beach" &&
            !selectedPlayer && (
              <GraphicButton
                onClick={() => {
                  previewGraphic("TeamTicker", {
                    game: game,
                    teams: teams,
                    players: players,
                    team: game.away_team,
                    squads: game.lineup?.away,
                    start: true,
                  });
                }}
              >
                Away Team Ticker
              </GraphicButton>
            )}

          {game?.competition?.competition_type !== "beach" &&
            !selectedPlayer &&
            !period?.kick_off &&
            period?.number === 1 && (
              <GraphicButton
                onClick={() => {
                  previewGraphic("Lineup", {
                    game: game,
                    teams: teams,
                    players: players,
                    team: game.home_team,
                    squads: game.lineup?.home,
                    manager: homeManager,
                    new: true,
                  });
                }}
              >
                Home Team List
              </GraphicButton>
            )}

          {game?.competition?.competition_type !== "beach" &&
            !selectedPlayer &&
            !period?.kick_off &&
            period?.number === 1 && (
              <GraphicButton
                onClick={() => {
                  previewGraphic("Lineup", {
                    game: game,
                    teams: teams,
                    players: players,
                    team: game.away_team,
                    sky: sky,
                    kits: kits,
                    squads: game.lineup?.away,
                    manager: awayManager,
                    new: true,
                  });
                }}
              >
                Away Team List
              </GraphicButton>
            )}

          {!selectedPlayer &&
            !period?.kick_off &&
            (period?.number === 1 ||
              game?.competition?.competition_type === "beach") && (
              <GraphicButton
                onClick={() => {
                  previewGraphic("Formation", {
                    game: game,
                    teams: teams,
                    players: players,
                    team: game.home_team,
                    squads: game.lineup?.home,
                    formation: game.home_formation,
                    manager: homeManager,
                    type: "home",
                  });
                }}
              >
                Home Formation
              </GraphicButton>
            )}

          {!selectedPlayer &&
            !period?.kick_off &&
            (period?.number === 1 ||
              game?.competition?.competition_type === "beach") && (
              <GraphicButton
                onClick={() => {
                  previewGraphic("Formation", {
                    game: game,
                    teams: teams,
                    players: players,
                    team: game.away_team,
                    squads: game.lineup?.away,
                    formation: game.away_formation,
                    manager: awayManager,
                    type: "away",
                  });
                }}
              >
                Away Formation
              </GraphicButton>
            )}

          {user?.username === "PremierSports" &&
            !selectedPlayer &&
            !period?.kick_off &&
            (period?.number === 1 ||
              game?.competition?.competition_type === "beach") && (
              <GraphicButton
                onClick={() => {
                  previewGraphic("Formation", {
                    game: game,
                    teams: teams,
                    players: players,
                    team: game.home_team,
                    squads: game.lineup?.home,
                    formation: game.home_formation,
                    manager: homeManager,
                    ots: true,
                    type: "home",
                  });
                }}
              >
                OTS Home Formation
              </GraphicButton>
            )}

          {user?.username === "PremierSports" &&
            !selectedPlayer &&
            !period?.kick_off &&
            (period?.number === 1 ||
              game?.competition?.competition_type === "beach") && (
              <GraphicButton
                onClick={() => {
                  previewGraphic("Formation", {
                    game: game,
                    teams: teams,
                    players: players,
                    team: game.away_team,
                    squads: game.lineup?.away,
                    formation: game.away_formation,
                    manager: awayManager,
                    ots: true,
                    type: "away",
                  });
                }}
              >
                OTS Away Formation
              </GraphicButton>
            )}

          {groups?.length > 0 &&
            groups?.map((g) => {
              return (
                <GraphicButton
                  onClick={async () => {
                    let new_group = await getGroup(g?._id);
                    debugger;
                    previewGraphic("Group Table", {
                      game: game,
                      teams: teams,
                      players: players,
                      team: game.away_team,
                      squads: game.lineup?.away,
                      formation: game.away_formation,
                      manager: awayManager,
                      group: new_group,
                    });
                  }}
                >
                  {g?.title}
                </GraphicButton>
              );
            })}
          {game?.competition?.competition_type === "beach" && (
            <GraphicButton
              onClick={async () => {
                let fixtures = await getFixtures({
                  date: game.date,
                });

                previewGraphic("Fixtures", {
                  fixtures,
                  game,
                });
              }}
            >
              Fixtures
            </GraphicButton>
          )}
          {!selectedPlayer && !period?.kick_off && (
            <GraphicButton
              onClick={async () => {
                let standings = await getStandings({
                  season: game?.season?._id,
                  date: game.date?.replace("Z", ""),
                });
                debugger;
                previewGraphic("Standings", {
                  game,
                  standings,
                });
              }}
            >
              Standings
            </GraphicButton>
          )}
        </Scroll>
      )}
      {/* <EndHalf game={game} /> */}

      {graphicInPreview && (
        <CurrentGraphic
          style={{ bottom: window.hideHeader === true ? "40px" : "10px" }}
        >
          {graphicInPreview.name}
          <GraphicButton
            style={{ height: "40px", backgroundColor: "#383f4a" }}
            onClick={() => {
              clearPreview(graphicInPreview.name);
            }}
          >
            CLEAR PVW (CTRL + Q)
          </GraphicButton>

          <GraphicButton
            green
            style={{ height: "40px" }}
            onClick={() => {
              animateGraphic(graphicInPreview.name);
            }}
          >
            IN (F8) or (-)
          </GraphicButton>
          <GraphicButton
            red
            style={{ height: "40px" }}
            onClick={() => {
              animateOffGraphic(graphicInPreview.name);
            }}
          >
            OUT (F9) or (*)
          </GraphicButton>
        </CurrentGraphic>
      )}
    </Main>
  );
}
