import React, { useContext, useEffect, useRef } from "react";
import styled from "styled-components";
import { UIContext } from "../../contexts/UIContext";
const Main = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  > iframe {
    border: none;
    width: 100%;
  }
`;
export default function Live() {
  const { setUIView } = useContext(UIContext);

  const frameRef = useRef();
  useEffect(() => {
    if (frameRef && frameRef.current) {
      function handleKeyDown(e) {
        console.log("iframe", e.keyCode);

        if (e.keyCode === 112) {
          setUIView(0);
        }
        if (e.keyCode === 113) {
          setUIView(1);
        }
        if (e.keyCode === 114) {
          setUIView(2);
        }
      }
      frameRef.current.onload = function () {
        frameRef.current.contentWindow.document.addEventListener(
          "keydown",
          handleKeyDown
        );
      };
    }
  }, [frameRef]);
  return (
    <Main>
      <iframe
        ref={frameRef}
        src="http://localhost:8085?project=PREMIER SPORTS&group=RUGBY"
      ></iframe>
    </Main>
  );
}
