import React, { useContext } from "react";
import styled from "styled-components";
import { FaInfo } from "react-icons/fa";
import { APIContext } from "../../../contexts/APIContext";
import { GraphicsContext } from "../../../contexts/GraphicsContext";
import { UIContext } from "../../../contexts/UIContext";
const Main = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
const Title = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  font-size: 0.7em;
`;
const InfoIcon = styled(FaInfo)`
  margin-right: 2px;
  position: absolute;
  left: 3px;
  font-size: 1.5em;
`;
const Content = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  font-size: 1.5em;
  align-items: center;
`;

export default function Opta70({ item, game }) {
  const { updateGame } = useContext(APIContext);
  const { updateGraphic } = useContext(GraphicsContext);
  const { periods } = useContext(UIContext);
  let time = item.qualifier.find((q) => q.qualifierId === 277)
    ? item.qualifier.find((q) => q.qualifierId === 277).value
    : 0;
  let period = [...(periods || [])].sort((a, b) => b.number - a.number)[0];
  return (
    <Main
      onClick={() => {
        let newData = { ...JSON.parse(JSON.stringify(game)) };
        let index = newData.live_data.periods.findIndex(
          (p) => p.number === period.number
        );
        newData.live_data.periods[index].added_time = parseInt(time);
        updateGame({ id: game._id, live_data: newData.live_data });
        updateGraphic("CLOCK", {
          type: "ADDED_TIME",
          added_time: time,
        });
      }}
    >
      <Title>
        <InfoIcon />
        Added Time
      </Title>
      <Content>+ {time}</Content>
    </Main>
  );
}
