export class TeamArrival {
  constructor({ animate, preview, update, animateOff }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.name = "TeamArrival";
    this.scene = "Name";
    this.data = [];
  }

  preview({ options }) {
    this.onAir = false;
    this.data = [];
    this.data.push({
      name: "Competition",
      value: { text: options.game?.competition?.name || "" },
    });
    this.data.push({
      name: "Competition Colour",
      value: { colour: options.game?.competition?.colour || "" },
    });
    this.data.push({
      name: "Competition Badge",
      value: { image: "${user}" + options.game?.competition?.badge || "" },
    });

    this.data.push({
      name: "Title",
      value: {
        text: options.player.title || "",
      },
    });
    this.data.push({
      name: "Shirt Number",
      value: {
        text:
          options.player.shirt_number ?? options.player.opta_shirt_number ?? "",
      },
    });
    this.data.push({
      name: "First Name",
      value: {
        text: options.player.first_name ?? options.player.opta_first_name ?? "",
      },
    });
    this.data.push({
      name: "Last Name",
      value: {
        text: options.player.last_name ?? options.player.opta_last_name ?? "",
      },
    });
    this.data.push({
      name: "First Name Uppercase",
      value: {
        text:
          options.player.first_name_upper ??
          options.player.opta_first_name?.toUpperCase() ??
          "",
      },
    });
    this.data.push({
      name: "Last Name Uppercase",
      value: {
        text:
          options.player.last_name_upper ??
          options.player.opta_last_name?.toUpperCase() ??
          "",
      },
    });
    this.data.push({
      name: "Full Name",
      value: {
        text:
          (options.player.first_name ?? options.player.opta_first_name ?? "") +
          " " +
          (options.player.last_name ?? options.player.opta_last_name ?? ""),
      },
    });
    this.data.push({
      name: "Full Name Uppercase",
      value: {
        text:
          (options.player.first_name_upper ??
            options.player.opta_first_name?.toUpperCase() ??
            "") +
          " " +
          (options.player.last_name_upper ??
            options.player.opta_last_name?.toUpperCase() ??
            ""),
      },
    });
    this.data.push({
      name: "Subtitle",
      value: {
        text: "Team arrival",
      },
    });
    this.data.push({
      name: "Team Name",
      value: {
        text: options.team?.name || "",
      },
    });

    this.data.push({
      name: "Goal Time",
      value: {
        text: "",
      },
    });

    this.data.push({
      name: "Team Badge",
      value: {
        image: "${user}" + options.team?.variables?.badge || "",
      },
    });
    this.data.push({
      name: "Team Secondary Badge",
      value: {
        image: "${user}" + options.team?.variables?.alt_badge || "",
      },
    });

    if (options?.game?.home_team?._id === options.team?._id) {
      this.data.push({
        name: "Team Kit BG Colour",
        value: {
          colour:
            options.team?.variables?.[
              options.game?.home_team_kit + "_kit_bg"
            ] || "",
        },
      });
      this.data.push({
        name: "Team Kit Text Colour",
        value: {
          colour:
            options.team?.variables?.[
              options.game?.home_team_kit + "_kit_text"
            ] || "",
        },
      });
    } else {
      this.data.push({
        name: "Team Kit BG Colour",
        value: {
          colour:
            options.team?.variables?.[
              options.game?.away_team_kit + "_kit_bg"
            ] || "",
        },
      });
      this.data.push({
        name: "Team Kit Text Colour",
        value: {
          colour:
            options.team?.variables?.[
              options.game?.away_team_kit + "_kit_text"
            ] || "",
        },
      });
    }

    this.sendPreview(this.scene, 500, this.data, "IN");
  }
  animate({ options }) {
    this.onAir = true;

    this.sendAnimate(this.scene, this.data);
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff(this.scene, []);
  }
}
