export class Formation {
  constructor({ animate, preview, update, animateOff }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.name = "Formation";
    this.scene = "Formation";
    this.data = [];
  }

  preview({ options }) {
    if (options.ots) {
      this.scene = "OTS Formation";
    } else {
      this.scene = "Formation";
    }
    this.onAir = false;
    this.data = [];

    this.data.push({
      name: "Team Type",
      value: { text: options.type || "" },
    });

    this.data.push({
      name: "Team Name",
      value: { text: options.team?.name || "" },
    });

    this.data.push({
      name: "Team Badge",
      value: {
        image: "${user}" + options.team?.variables?.badge,
      },
    });

    this.data.push({
      name: "Team Secondary Badge",
      value: {
        image: "${user}" + options.team?.variables?.alt_badge,
      },
    });

    this.data.push({
      name: "Team Video Badge",
      value: {
        video: "${user}" + options.team?.variables?.video_badge,
      },
    });

    this.data.push({
      name: "Team Secondary Badge",
      value: {
        image: "${user}" + options.team?.variables?.alt_badge,
      },
    });

    this.data.push({
      name: "Sponsor Image",
      value: {
        image: "${user}" + options.team?.variables?.lineup_sponsor,
      },
    });

    if (options?.game?.home_team?._id === options.team?._id) {
      this.data.push({
        name: "Team Kit BG Colour",
        value: {
          colour:
            options.team?.variables?.[
              options.game?.home_team_kit + "_kit_bg"
            ] || "",
        },
      });
      this.data.push({
        name: "Team Kit Text Colour",
        value: {
          colour:
            options.team?.variables?.[
              options.game?.home_team_kit + "_kit_text"
            ] || "",
        },
      });
    } else {
      this.data.push({
        name: "Team Kit BG Colour",
        value: {
          colour:
            options.team?.variables?.[
              options.game?.away_team_kit + "_kit_bg"
            ] || "",
        },
      });
      this.data.push({
        name: "Team Kit Text Colour",
        value: {
          colour:
            options.team?.variables?.[
              options.game?.away_team_kit + "_kit_text"
            ] || "",
        },
      });
    }

    this.data.push({
      name: "Competition",
      value: { text: options.game?.competition?.name || "" },
    });
    this.data.push({
      name: "Competition Colour",
      value: { colour: options.game?.competition?.colour || "" },
    });
    this.data.push({
      name: "Competition Badge",
      value: { image: "${user}" + options.game?.competition?.badge || "" },
    });

    this.data.push({
      name: "Stadium",
      value: {
        text:
          options.game?.stadium?.name || options.game?.stadium?.opta_name || "",
      },
    });

    let manager = options.manager;

    this.data.push({
      name: "Manager First Name",
      value: {
        text:
          manager?.player?.first_name ?? manager?.player?.opta_first_name ?? "",
      },
    });

    this.data.push({
      name: "Manager Last Name",
      value: {
        text:
          manager?.player?.last_name ?? manager?.player?.opta_last_name ?? "",
      },
    });

    this.data.push({
      name: "Manager First Name Uppercase",
      value: {
        text:
          manager?.player?.first_name_upper ??
          manager?.player?.opta_first_name?.toUpperCase() ??
          "",
      },
    });

    this.data.push({
      name: "Manager Last Name Uppercase",
      value: {
        text:
          manager?.player?.last_name_upper ??
          manager?.player?.opta_last_name?.toUpperCase() ??
          "",
      },
    });

    this.data.push({
      name: "Manager Full Name",
      value: {
        text:
          (manager?.player?.first_name ??
            manager?.player?.opta_first_name ??
            "") +
          " " +
          (manager?.player?.last_name ?? manager?.player?.opta_last_name ?? ""),
      },
    });

    this.data.push({
      name: "Manager Full Name Uppercase",
      value: {
        text:
          (manager?.player?.first_name_upper ??
            manager?.player?.opta_first_name?.toUpperCase() ??
            "") +
          " " +
          (manager?.player?.last_name_upper ??
            manager?.player?.opta_last_name?.toUpperCase() ??
            ""),
      },
    });

    this.data.push({
      name: "Manager Title",
      value: {
        text: manager?.player?.title || "Manager",
      },
    });

    new Array(11).fill(1).forEach((_, index) => {
      let row = index + 1;

      let player = options.squads?.find((s) => s.order === row);

      this.data.push({
        name: "First Name " + row,
        value: {
          text:
            player?.squad?.player?.first_name ??
            player?.squad?.player?.opta_first_name ??
            "",
        },
      });

      this.data.push({
        name: "Last Name " + row,
        value: {
          text:
            player?.squad?.player?.last_name ??
            player?.squad?.player?.opta_last_name ??
            "",
        },
      });

      this.data.push({
        name: "First Name Uppercase " + row,
        value: {
          text:
            player?.squad?.player?.first_name_upper ??
            player?.squad?.player?.opta_last_name?.toUpperCase() ??
            "",
        },
      });

      this.data.push({
        name: "Last Name Uppercase " + row,
        value: {
          text:
            player?.squad?.player?.last_name_upper ??
            player?.squad?.player?.opta_last_name?.toUpperCase() ??
            "",
        },
      });

      this.data.push({
        name: "Captain " + row,
        value: {
          visible: player?.captain || false,
        },
      });
      this.data.push({
        name: "Position " + row,
        value: {
          text: player?.squad?.position || "",
        },
      });
      this.data.push({
        name: "Shirt Number " + row,
        value: {
          text:
            player?.squad?.shirt_number ??
            player?.squad?.opta_shirt_number ??
            "",
        },
      });

      this.data.push({
        name: "X/Y " + row,
        value: {
          position: {
            x: options.formation.positions[index].x,
            y: options.formation.positions[index].y,
          },
        },
      });
    });

    new Array(19).fill(1).forEach((_, index) => {
      let row = index + 12;

      let player = options.squads?.find((s) => s.order === row);

      this.data.push({
        name: "First Name " + row,
        value: {
          text:
            player?.squad?.player?.first_name ??
            player?.squad?.player?.opta_first_name ??
            "",
        },
      });

      this.data.push({
        name: "Last Name " + row,
        value: {
          text:
            player?.squad?.player?.last_name ??
            player?.squad?.player?.opta_last_name ??
            "",
        },
      });

      this.data.push({
        name: "First Name Uppercase " + row,
        value: {
          text:
            player?.squad?.player?.first_name_upper ??
            player?.squad?.player?.opta_first_name?.toUpperCase() ??
            "",
        },
      });

      this.data.push({
        name: "Last Name Uppercase " + row,
        value: {
          text:
            player?.squad?.player?.last_name_upper ??
            player?.squad?.player?.opta_last_name?.toUpperCase() ??
            "",
        },
      });

      this.data.push({
        name: "Captain " + row,
        value: {
          visible: player?.captain || false,
        },
      });
      this.data.push({
        name: "Position " + row,
        value: {
          text: player?.squad?.position || "",
        },
      });
      this.data.push({
        name: "Shirt Number " + row,
        value: {
          text:
            player?.squad?.shirt_number ??
            player?.squad?.opta_shirt_number ??
            "",
        },
      });

      this.data.push({
        name: "X/Y " + row,
        value: {
          position: {
            x: options.formation?.positions?.[index]?.x,
            y: options.formation?.positions?.[index]?.y,
          },
        },
      });
    });

    this.sendPreview(this.scene, 500, this.data, "IN");
  }
  animate({ options }) {
    this.onAir = true;

    this.sendAnimate(this.scene, this.data);
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff(this.scene, []);
  }
}
