import React from "react";
import styled from "styled-components";

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 90%;
  border-bottom: 2px solid #202731;
`;
const StatDiv = styled.div`
  display: flex;
`;
const Category = styled.div`
  display: flex;
  min-width: 60%;
  justify-content: center;
  padding: 0.5em;
`;
const Value = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  cursor: pointer;
  padding: 0.5em;
  border-radius: 2px;
  :hover {
    background-color: #db0a41;
  }
`;
const Bar = styled.div`
  display: flex;
  width: 100%;
`;
const InnerBar = styled.div`
  display: flex;
`;
const Bars = styled.div`
  display: flex;
  width: 100%;
`;
export default function ActionAreas({ category, stat, updateStat }) {
  return (
    <Main>
      <Category>{category}</Category>
      <StatDiv>
        <Value
          onContextMenu={(e) => {
            e.preventDefault();
          }}
          // onMouseDown={(e) => {
          //   let newStat = { ...stat };
          //   switch (e.button) {
          //     case 0:
          //       newStat.home += 1;
          //       updateStat(newStat);
          //       break;
          //     case 2:
          //       newStat.home -= 1;
          //       updateStat(newStat);
          //       break;
          //   }
          // }}
        >
          0%
        </Value>

        <Value
          onContextMenu={(e) => {
            e.preventDefault();
          }}
          // onMouseDown={(e) => {
          //   let newStat = { ...stat };
          //   switch (e.button) {
          //     case 0:
          //       newStat.away += 1;
          //       updateStat(newStat);
          //       break;
          //     case 2:
          //       newStat.away -= 1;
          //       updateStat(newStat);
          //       break;
          //   }
          // }}
        >
          0%
        </Value>
        <Value
          onContextMenu={(e) => {
            e.preventDefault();
          }}
          // onMouseDown={(e) => {
          //   let newStat = { ...stat };
          //   switch (e.button) {
          //     case 0:
          //       newStat.away += 1;
          //       updateStat(newStat);
          //       break;
          //     case 2:
          //       newStat.away -= 1;
          //       updateStat(newStat);
          //       break;
          //   }
          // }}
        >
          0%
        </Value>
      </StatDiv>
    </Main>
  );
}
