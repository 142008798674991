let home_sponsors = [
  { mins: 6, processed: false },
  { mins: 16, processed: false },
  { mins: 26, processed: false },
  { mins: 36, processed: false },
  { mins: 51, processed: false },
  { mins: 61, processed: false },
  { mins: 71, processed: false },
  { mins: 81, processed: false },
];
let away_sponsors = [
  { mins: 11, processed: false },
  { mins: 21, processed: false },
  { mins: 31, processed: false },
  { mins: 41, processed: false },
  { mins: 56, processed: false },
  { mins: 66, processed: false },
  { mins: 76, processed: false },
  { mins: 86, processed: false },
];
const delay = 5000;

export function reset() {
  home_sponsors = home_sponsors?.map((s) => {
    return { ...s, processed: false };
  });
  away_sponsors = away_sponsors?.map((s) => {
    return { ...s, processed: false };
  });
}
export default function update({ game, time, animate, animateOff }) {
  let sponsor = home_sponsors?.find(
    (s) => s.processed === false && s.mins === parseInt(time?.split(":")?.[0])
  );
  if (sponsor) {
    sponsor.processed = true;
    sendSponsor({ animate, team: game?.home_team, index: 1, animateOff, game });
  }

  sponsor = away_sponsors?.find(
    (s) => s.processed === false && s.mins === parseInt(time?.split(":")?.[0])
  );
  if (sponsor) {
    sponsor.processed = true;
    sendSponsor({ animate, team: game?.away_team, index: 1, animateOff, game });
  }
}

function sendSponsor({ game, team, index, animate, animateOff }) {
  if (team?.variables?.["sponsor_" + index]) {
    console.log("animating sponsor", index);
    animate("TEAM_SPONSOR", {
      sponsor_image: team?.variables?.["sponsor_" + index],
      team,
      game,
    });
    setTimeout(() => {
      sendSponsor({ game, team, index: index + 1, animate, animateOff });
    }, delay);
  } else if (index > 1) {
    console.log("animating off sponsor");
    animateOff("TEAM_SPONSOR");
  }
}
