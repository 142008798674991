import React, { useState, useEffect, useContext, useCallback } from "react";

const UIContext = React.createContext([{}, () => {}]);
const VIEW_CONSTS = {
  matchSelect: 0,
  live: 1,
  formation: 2,
  stats: 3,
  clockEdit: 4,
  all_graphics: 5,
  penalty: 6,
};

const MODE_CONSTS = {
  live: 0,
  matchChoice: 1,
};

const UIProvider = (props) => {
  const [view, setView] = useState(VIEW_CONSTS.matchSelect);
  const [mode, setMode] = useState(MODE_CONSTS.live);
  const [goal, setGoal] = useState();
  const [selectedTeam, setSelectedTeam] = useState();
  const [editPlayer, setEditPlayer] = useState();
  const [uiView, setUIView] = useState(0);
  const [periods, setPeriods] = useState([]);
  const [homeScorers, setHomeScorers] = useState([]);
  const [awayScorers, setAwayScorers] = useState([]);
  const [homePenalties, setHomePenalties] = useState([]);
  const [awayPenalties, setAwayPenalties] = useState([]);
  return (
    <UIContext.Provider
      value={{
        view,
        setView,
        selectedTeam,
        setSelectedTeam,
        goal,
        setGoal,
        mode,
        setMode,
        editPlayer,
        setEditPlayer,
        uiView,
        setUIView,
        periods,
        setPeriods,
        homeScorers,
        setHomeScorers,
        awayScorers,
        setAwayScorers,
        homePenalties,
        setHomePenalties,
        awayPenalties,
        setAwayPenalties,
      }}
    >
      {props.children}
    </UIContext.Provider>
  );
};

export { UIContext, UIProvider, VIEW_CONSTS, MODE_CONSTS };
