import React from "react";
import moment from "moment";
import styled from "styled-components";
const Main = styled.div`
  display: flex;
  background-color: ${(props) => (props.selected ? "#d11e42" : "#202731")};
  justify-content: center;
  padding: 0.5em;
  border-radius: 4px;
  margin: 0.5em;
  cursor: pointer;
  flex-direction: column;
  align-items: center;
  width: 150px;
`;

export default function Date({
  selectedDate,
  date,
  setDate,
  fixtures,
  competition,
}) {
  let total = fixtures.filter((fx) => {
    return (
      moment(fx.date, "YYYY-MM-DD Z").isSame(date, "date") &&
      fx.competition &&
      fx.competition._id === competition._id
    );
  }).length;

  return (
    <Main
      selected={selectedDate === date}
      onClick={() => {
        setDate(date);
      }}
    >
      {date === "2021-02-28" && <div>Test Games</div>}
      {date !== "2021-02-28" && (
        <div>
          {moment(date).calendar(null, {
            sameDay: "[Today]",
            nextDay: "[Tomorrow]",
            nextWeek: "dddd",
            lastDay: "[Yesterday]",
            lastWeek: "dddd Do",
            sameElse: "DD/MM/YYYY",
          })}
        </div>
      )}
      {/* <div>
        {total} {total > 1 || total == 0 ? "Games" : "Game"}
      </div> */}
    </Main>
  );
}
