import React from "react";
import styled from "styled-components";
import moment from "moment";

const Main = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 1em;
  align-items: center;
  box-sizing: border-box;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  background-color: #181d25;
  border-bottom: 3px solid #00fffc;
  height: 72px;
  padding: 0.5em;
`;

const Team = styled.div`
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  display: flex;
  align-items: center;
`;
const TeamName = styled.div`
  padding-left: ${(props) => (props.home ? "0.5em" : "")};
  display: flex;
  font-size: 1.5em;
`;
const Minute = styled.div`
  margin-left: auto;
  display: flex;
  font-size: 1.5em;
  align-items: center;
`;
const Button = styled.div`
  padding: 0.5em;
  min-width: 50px;
  display: flex;
  background-color: #202731;
  justify-content: center;
  align-items: center;
  height: 100%;
  border-radius: 4px;
  margin: 0.5em;
  box-sizing: border-box;
  :hover {
    background-color: #db0a41;
    cursor: pointer;
  }
`;
export default function Goal({ goal, setGoal, cancel }) {
  return (
    <Main>
      <Team>
        <TeamName home>
          {goal.team.name} GOAL! - Select the player that scored
        </TeamName>
      </Team>
      <Minute>
        <Button
          onClick={() => {
            setGoal({ ...goal, time: goal.time - 60000 });
          }}
        >
          -
        </Button>
        {moment(goal.time).format("mm")}'
        <Button
          onClick={() => {
            setGoal({ ...goal, time: goal.time + 60000 });
          }}
        >
          +
        </Button>
        <Button
          onClick={() => {
            setGoal({ ...goal, time: goal.time - 1000 });
          }}
        >
          -
        </Button>
        {moment(goal.time).format("ss")}''
        <Button
          onClick={() => {
            setGoal({ ...goal, time: goal.time + 1000 });
          }}
        >
          +
        </Button>
      </Minute>
      <Button
        onClick={() => {
          cancel();
        }}
      >
        CANCEL GOAL
      </Button>
    </Main>
  );
}
