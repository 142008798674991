import React from "react";
import styled from "styled-components";
import { FaInfo } from "react-icons/fa";
const Main = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
const Title = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  font-size: 0.7em;
`;
const InfoIcon = styled(FaInfo)`
  margin-right: 2px;
  position: absolute;
  left: 3px;
  font-size: 1.5em;
`;
const Content = styled.div`
  display: flex;
  height: 100%;
  font-size: 1.2em;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export default function Opta4({ item, player }) {
  return (
    <Main>
      <Title>
        <InfoIcon />
        Foul {item.outcome === 0 ? " Commited" : " Against"}
      </Title>
      <Content>
        {player?.squad?.shirt_number ?? player?.squad?.opta_shirt_number}.{" "}
        {player?.squad?.player?.last_name ??
          player?.squad?.player?.opta_last_name}
      </Content>
    </Main>
  );
}
