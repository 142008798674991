import React from "react";
import styled from "styled-components";
import moment from "moment";
import { useContext } from "react";
import { DataContext } from "../../contexts/DataContext";
import { APIContext } from "../../contexts/APIContext";
const Main = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Half = styled.div`
  display: flex;
  width: 49%;
  flex-direction: column;
`;

const Row = styled.div`
  padding-left: 0.5em;
  display: flex;
  width: 100%;
  min-height: ${(props) => (!props.noHover ? "39px" : "")};
  padding-right: 0.5em;
  font-size: 1em;
  font-weight: 100;
  align-items: center;
  box-sizing: border-box;
  opacity: ${(props) => (props.sub ? "0.5" : "1")};
  background-color: ${(props) =>
    props.fade ? "#181d25" : props.selected ? "#db0a41" : "#202731"};
  border-bottom: 2px solid #181d25;
  cursor: ${(props) => (props.clickable ? "pointer" : "")};
`;

const PlayerName = styled.div`
  display: flex;
`;

const Minute = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
`;
const Button = styled.div`
  padding: 0.5em;
  min-width: 50px;
  display: flex;
  background-color: ${(props) => (props.selected ? "#db0a41" : "#0e1219")};
  justify-content: center;
  align-items: center;
  height: 100%;
  border-radius: 4px;
  margin: 0.5em;
  box-sizing: border-box;
  :hover {
    background-color: #db0a41;
    color: #fff;
    cursor: pointer;
  }
`;

export default function Goals({ game, homeLineup, awayLineup }) {
  const { updateGame } = useContext(APIContext);

  function setHomeGoal(goal) {
    let newScorers = [...game.home_scorers] || [];
    let index = newScorers.findIndex((g) => g._id === goal._id);
    if (index > -1) {
      newScorers[index] = {
        ...newScorers[index],
        time: goal.time,
        penalty: goal.penalty,
      };
    }

    updateGame({
      id: game._id,
      home_scorers: newScorers,
    });
  }

  function removeHomeGoal(goal) {
    let newScorers = [...game.home_scorers] || [];
    newScorers = newScorers.filter((g) => g._id !== goal._id);
    updateGame({
      id: game._id,
      home_scorers: newScorers,
    });
  }

  function setAwayGoal(goal) {
    let newScorers = [...game.away_scorers] || [];
    let index = newScorers.findIndex((g) => g._id === goal._id);
    if (index > -1) {
      newScorers[index] = {
        ...newScorers[index],
        time: goal.time,
        penalty: goal.penalty,
      };
    }
    updateGame({
      id: game._id,
      away_scorers: newScorers,
    });
  }

  function removeAwayGoal(goal) {
    let newScorers = [...game.away_scorers] || [];
    newScorers = newScorers.filter((g) => g._id !== goal._id);
    updateGame({
      id: game._id,
      away_scorers: newScorers,
    });
  }

  return (
    <Main>
      <Half>
        {game.home_scorers?.map((goal) => {
          let player = [...homeLineup, ...awayLineup]?.find(
            (p) => p.squad?._id === goal.player
          );
          player = player?.squad?.player;
          return (
            <Row>
              <PlayerName>
                {player?.first_name} {player?.last_name}{" "}
                {goal.own_goal ? "(OG)" : ""}
              </PlayerName>{" "}
              <Minute>
                <Button
                  selected={goal.penalty}
                  onClick={() => {
                    setHomeGoal({
                      ...goal,
                      penalty: goal.penalty ? false : true,
                      prevTime: goal.time,
                    });
                  }}
                >
                  PENALTY
                </Button>
                <Button
                  onClick={() => {
                    setHomeGoal({
                      ...goal,
                      time: goal.time - 60000,
                      prevTime: goal.time,
                    });
                  }}
                >
                  -
                </Button>
                {moment.duration(goal.time).minutes() +
                  moment.duration(goal.time).hours() * 60}
                '
                <Button
                  onClick={() => {
                    setHomeGoal({
                      ...goal,
                      time: goal.time + 60000,
                      prevTime: goal.time,
                    });
                  }}
                >
                  +
                </Button>
                <Button
                  onClick={() => {
                    setHomeGoal({
                      ...goal,
                      time: goal.time - 1000,
                      prevTime: goal.time,
                    });
                  }}
                >
                  -
                </Button>
                {moment(goal.time).format("ss")}''
                <Button
                  onClick={() => {
                    setHomeGoal({
                      ...goal,
                      time: goal.time + 1000,
                      prevTime: goal.time,
                    });
                  }}
                >
                  +
                </Button>
                <Button
                  onClick={() => {
                    removeHomeGoal({ ...goal });
                  }}
                >
                  REMOVE GOAL
                </Button>
              </Minute>
            </Row>
          );
        })}
      </Half>
      <Half>
        {game.away_scorers?.map((goal) => {
          let player = [...homeLineup, ...awayLineup]?.find(
            (p) => p.squad?._id === goal.player
          );
          return (
            <Row>
              <PlayerName>
                {player?.first_name} {player?.last_name}{" "}
                {goal.own_goal ? "(OG)" : ""}
              </PlayerName>{" "}
              <Minute>
                <Button
                  selected={goal.penalty}
                  onClick={() => {
                    setAwayGoal({
                      ...goal,
                      penalty: goal.penalty ? false : true,
                      prevTime: goal.time,
                    });
                  }}
                >
                  PENALTY
                </Button>
                <Button
                  onClick={() => {
                    setAwayGoal({
                      ...goal,
                      time: goal.time - 60000,
                      prevTime: goal.time,
                    });
                  }}
                >
                  -
                </Button>
                {moment.duration(goal.time).minutes() +
                  moment.duration(goal.time).hours() * 60}
                '
                <Button
                  onClick={() => {
                    setAwayGoal({
                      ...goal,
                      time: goal.time + 60000,
                      prevTime: goal.time,
                    });
                  }}
                >
                  +
                </Button>
                <Button
                  onClick={() => {
                    setAwayGoal({
                      ...goal,
                      time: goal.time - 1000,
                      prevTime: goal.time,
                    });
                  }}
                >
                  -
                </Button>
                {moment(goal.time).format("ss")}''
                <Button
                  onClick={() => {
                    setAwayGoal({
                      ...goal,
                      time: goal.time + 1000,
                      prevTime: goal.time,
                    });
                  }}
                >
                  +
                </Button>
                <Button
                  onClick={() => {
                    removeAwayGoal({ ...goal });
                  }}
                >
                  REMOVE GOAL
                </Button>
              </Minute>
            </Row>
          );
        })}
      </Half>
    </Main>
  );
}
