import React, { useContext } from "react";
import styled from "styled-components";
import { ControlContext } from "../../contexts/ControlContext";

const Main = styled.iframe`
  box-sizing: border-box;
  width: ${(props) => props.width + "px"};
  height: ${(props) => props.height + "px"};
  background-color: #191f2b;
  z-index: -1;
  /* border-radius: 8px; */
  /* box-shadow: 0px 4px 12px -20px rgba(0, 0, 0, 0.2),
    0px 8px 20px 2px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12); */
  border: none;
  overflow: hidden;
  margin: 5px;
  pointer-events: none;
  border: ${({ connected }) => (connected === true ? "2px solid #30c17e" : "")};
  border-radius: 4px;
`;
function Engine() {
  const { selectedEngine } = useContext(ControlContext);
  let previewH = 1080 / 1.7;
  let previewW = 1920 / 1.7;

  return (
    <Main
      width={previewW}
      height={previewH}
      id="PreviewEngine"
      name="preview"
      title="Preview"
      src={`${
        window.ENV?.REACT_APP_ENGINE_URL || process.env.REACT_APP_ENGINE_URL
      }/preview/${selectedEngine}/${
        window.ENV?.REACT_APP_PROJECT_ID || process.env.REACT_APP_PROJECT_ID
      }?width=${previewW}&height=${previewH}&command_server=${
        window.ENV?.REACT_APP_COMMAND_SERVER ||
        process.env.REACT_APP_COMMAND_SERVER
      }`}
    ></Main>
  );
}

export default React.memo(Engine);
