import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import Sub from "./Sub";
import { DataContext } from "../../contexts/DataContext";
import { APIContext } from "../../contexts/APIContext";
import { Button } from "../../Theme/Hyper";
const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;
const Team = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const TeamName = styled.div`
  display: flex;
  padding: 0.5em;
`;
const AllButton = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5em;
  background-color: #181d25;
  border-radius: 4px;
  margin: 2px;
  cursor: pointer;
  justify-content: center;
`;
export default function Subs({
  game,
  homeSubs,
  awaySubs,
  previewGraphic,
  countries,
  sky,
}) {
  const { updateGame } = useContext(APIContext);

  let subsHome = [];
  for (let i = 1; i < 4; i++) {
    let s = homeSubs
      ?.filter((a) => a.sub_order === i)
      ?.sort((a, b) => a.order - b.order)
      ?.map((p) => {
        return {
          ...p,
          ...game.lineup?.home?.find((player) => player === p.player),
          team: game.home_team,
        };
      });
    if (s?.length > 0) {
      subsHome.push({ players: s });
    }
  }
  let subsAway = [];
  for (let i = 1; i < 4; i++) {
    let s = awaySubs
      ?.filter((a) => a.sub_order === i)
      ?.sort((a, b) => a.order - b.order)
      ?.map((p) => {
        return {
          ...p,
          team: game.away_team,
        };
      });
    if (s?.length > 0) {
      subsAway.push({ players: s });
    }
  }

  let homeBGColour =
    game.home_team?.variables?.[game?.home_team_kit + "_kit_bg"] || "";
  let awayBGColour =
    game.away_team?.variables?.[game?.away_team_kit + "_kit_bg"] || "";

  return (
    <Main>
      {homeSubs && homeSubs.length > 0 && (
        <Team>
          <TeamName
            style={{
              borderBottom: "2px solid " + (homeBGColour || "rgba(0,0,0,0)"),
            }}
          >
            {game?.home_team?.name}
          </TeamName>
          <Button
            onClick={() => {
              let newLineup = [...game?.lineup?.home];

              newLineup = newLineup.map((item) => {
                return {
                  ...item,
                  squad: item.squad._id,
                  sub_order: null,
                };
              });

              newLineup = {
                home: newLineup,
                away: game.lineup.away,
              };

              updateGame({
                id: game._id,
                lineup: newLineup,
              });
            }}
          >
            Cancel Subs
          </Button>
          {subsHome.map((sub) => {
            return (
              <Sub
                previewGraphic={previewGraphic}
                players={sub.players}
                countries={countries}
                sky={sky}
                game={game}
                team={game.home_team}
              ></Sub>
            );
          })}
          {subsHome.length > 1 && (
            <AllButton
              onClick={() => {
                if (subsHome.length === 2) {
                  previewGraphic("TwoSubstitution", {
                    subs: subsHome,
                    team: game.home_team,
                    game: game,
                  });
                } else if (subsHome.length === 3) {
                  previewGraphic("ThreeSubstitution", {
                    subs: subsHome,
                    team: game.home_team,
                    game: game,
                  });
                }
              }}
            >
              ALL
            </AllButton>
          )}
          <Button
            green
            onClick={() => {
              let subs = [
                ...game.lineup.home
                  .filter((p) => p.sub_order)
                  .map((n) => {
                    return { ...n };
                  }),
              ];

              let newLineup = [...game?.lineup?.home];

              newLineup = newLineup.map((item) => {
                return {
                  ...item,
                  squad: item.squad._id,
                  sub_order: null,
                };
              });

              subs.forEach((sub) => {
                let index = newLineup.findIndex(
                  (nl) => nl.squad === sub.squad._id
                );
                newLineup[index].subbed = true;

                let newOrder = subs.find(
                  (s) =>
                    s.sub_order === sub.sub_order &&
                    sub.squad._id !== s.squad._id
                ).order;
                newLineup[index].order = newOrder;
                newLineup[index].sub_order = null;
              });

              newLineup = {
                home: newLineup,
                away: game.lineup.away,
              };

              updateGame({
                id: game._id,
                lineup: newLineup,
              });
            }}
          >
            Apply Subs
          </Button>
        </Team>
      )}
      {awaySubs && awaySubs.length > 0 && (
        <Team>
          <TeamName
            style={{
              borderBottom: "2px solid " + (awayBGColour || "rgba(0,0,0,0)"),
            }}
          >
            {game.away_team?.name}
          </TeamName>
          <Button
            onClick={() => {
              let newLineup = [...game?.lineup?.away];

              newLineup = newLineup.map((item) => {
                return {
                  ...item,
                  squad: item.squad._id,
                  sub_order: null,
                };
              });

              newLineup = {
                home: game.lineup.home,
                away: newLineup,
              };

              updateGame({
                id: game._id,
                lineup: newLineup,
              });
            }}
          >
            Cancel Subs
          </Button>
          {subsAway.map((sub) => {
            return (
              <Sub
                sky={sky}
                previewGraphic={previewGraphic}
                players={sub.players}
                countries={countries}
                game={game}
                team={game.away_team}
              ></Sub>
            );
          })}
          {subsAway.length > 1 && (
            <AllButton
              onClick={() => {
                if (subsAway.length === 2) {
                  previewGraphic("TwoSubstitution", {
                    subs: subsAway,
                    team: game.away_team,
                    game: game,
                  });
                } else if (subsAway.length === 3) {
                  previewGraphic("ThreeSubstitution", {
                    subs: subsAway,
                    team: game.away_team,
                    game: game,
                  });
                }
              }}
            >
              ALL
            </AllButton>
          )}
          <Button
            green
            onClick={() => {
              let subs = [
                ...game.lineup.away
                  .filter((p) => p.sub_order)
                  .map((n) => {
                    return { ...n };
                  }),
              ];

              let newLineup = [...game?.lineup?.away];

              newLineup = newLineup.map((item) => {
                return {
                  ...item,
                  squad: item.squad._id,
                  sub_order: null,
                };
              });

              subs.forEach((sub) => {
                let index = newLineup.findIndex(
                  (nl) => nl.squad === sub.squad._id
                );
                newLineup[index].subbed = true;

                let newOrder = subs.find(
                  (s) =>
                    s.sub_order === sub.sub_order &&
                    sub.squad._id !== s.squad._id
                ).order;
                newLineup[index].order = newOrder;
                newLineup[index].sub_order = null;
              });

              newLineup = {
                home: game.lineup.home,
                away: newLineup,
              };

              updateGame({
                id: game._id,
                lineup: newLineup,
              });
            }}
          >
            Apply Subs
          </Button>
        </Team>
      )}
    </Main>
  );
}
